import { LitElement, html, css } from "lit";
import { customElement, property, state, query, queryAll } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { keyed } from "lit/directives/keyed.js";
import {
    Employee,
    Contract,
    TimeEntry,
    TimeEntryType,
    timeEntryTypeColor,
    Department,
    RosterTemplate,
    RosterTargets,
    Absence,
    AbsenceStatus,
    RosterNote,
    RosterTab,
    Venue,
    Availability,
    AvailabilityStatus,
    EmployeeStatus,
    employeeStatusLabel,
    employeeStatusColor,
    Position,
    TimeBalance,
    ShiftTemplate,
    DailyResults,
} from "@pentacode/core/src/model";
import { Issue } from "@pentacode/core/src/issues";
import {
    UpdateVenueParams,
    GetRosterTargetsParams,
    GetAbsencesParams,
    GetRosterNotesParams,
    GetPublicRosterUrlParams,
    GetAvailabilitesParams,
    GetDailyResultsParams,
} from "@pentacode/core/src/api";
import {
    parseDateString,
    toDateString,
    getRange,
    dateAdd,
    formatDate,
    formatWeekDayShort,
    debounce,
    toDurationString,
    wait,
    parseTimes,
    toTimeString,
    formatWeekDay,
    formatDateShort,
    inferRangeType,
} from "@pentacode/core/src/util";
import { Holiday, getHolidayForDate } from "@pentacode/core/src/holidays";
import { getIssues } from "@pentacode/core/src/issues";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { print } from "../lib/print";
import { isCursorInInput, isSafari, setClipboard } from "../lib/util";
import { app } from "../init";
import { shared, mixins, colors } from "../styles";
import "./scroller";
import { entryPositions, RosterEntry } from "./roster-entry";
import "./roster-entry";
import "./avatar";
import { Dialog } from "./dialog";
import { alert, confirm } from "./alert-dialog";
import "./spinner";
import { Checkbox } from "./checkbox";
import "./date-picker";
import { Balance } from "./balance";
import { RosterCosts } from "./roster-costs";
import { RosterTargetsElement } from "./roster-targets";
import { EmployeeDay } from "./employee-day";
import "./progress";
import "./roster-tabs";
import { PublishRosterDialog } from "./publish-roster-dialog";
import { singleton } from "../lib/singleton";
import { AbsenceDialog } from "./absence-dialog";
import { RosterNotePopover } from "./roster-note-popover";
import { cache } from "lit/directives/cache.js";
import { until } from "lit/directives/until.js";
import { SendMessageDialog } from "./send-message-dialog";
import { AvailabilityDialog } from "./availability-dialog";
import { Popover } from "./popover";
import { AutoAssignMenu } from "./auto-assign-menu";
import { DateString, Hours, add, subtract } from "@pentacode/openapi/src/units";
import { live } from "lit/directives/live.js";
import { CreateRosterTemplateDialog } from "./create-roster-template-dialog";
import { DateRange, getNominalTime, getTimeResult, makeTimeResult } from "@pentacode/core/src/time";
import "./drawer";
import "./roster-changes";
import { popover } from "../directives/popover";

interface DayData {
    employee: number;
    date: DateString;
    entries: TimeEntry[];
    blocked: boolean;
    blockedReason: string;
    today: boolean;
    isPast: boolean;
    readonly: boolean;
    absence?: Absence;
    availabilities: Availability[];
    holiday: Holiday | null;
}

interface RosterData {
    dates: DateString[];
    departments: DepartmentData[];
    holidays: Map<string, Holiday | null>;
}

export interface EmployeeData {
    employee: Employee;
    contract: Contract;
    timeBalance: TimeBalance;
}

interface DepartmentData {
    department: Department;
    employees: {
        employeeData: EmployeeData;
        days: DayData[];
    }[];
    unassigned: {
        date: DateString;
        entries: TimeEntry[];
    }[];
}

export interface DragData {
    entry?: Partial<TimeEntry>;
    rosterTemplate?: RosterTemplate;
    shiftTemplate?: ShiftTemplate;
    imageOffset?: { x: number; y: number };
}

@customElement("ptc-roster-row")
export class RosterRow extends LitElement {
    @property({ attribute: false })
    employee!: EmployeeData;

    @property({ attribute: false })
    days: DayData[] = [];

    @property({ attribute: false })
    department!: DepartmentData;

    @property({ attribute: false })
    venue!: Venue;

    @property({ attribute: false })
    issues!: Issue[];

    @property({ type: Boolean })
    isVisible = false;

    @property()
    activeDate!: string | null;

    @property({ attribute: false })
    activeEmployee!: number | null;

    @property({ attribute: false })
    activeDepartment!: number | null;

    @property()
    activeEntry!: string | null;

    @property({ attribute: false })
    activeTab!: RosterTab;

    @property({ type: Boolean })
    canMoveUp: boolean;

    @property({ type: Boolean })
    canMoveDown: boolean;

    createRenderRoot() {
        return this;
    }

    shouldUpdate(changes: Map<string, any>) {
        return changes.has("isVisible") || this.isVisible;
    }

    private _dragstart(e: DragEvent, data: DragData) {
        const el = e.target as HTMLElement;
        const imgEl = (el.shadowRoot && (el.shadowRoot.querySelector(".container") as HTMLElement)) || el;
        const dt = e.dataTransfer!;
        dt.setData("text/plain", "42");
        dt.effectAllowed = "all";
        dt.dropEffect = "move";
        data.imageOffset = { x: imgEl.offsetWidth / 2, y: imgEl.offsetHeight / 2 };
        dt.setDragImage(
            imgEl,
            data.imageOffset.x * window.devicePixelRatio,
            data.imageOffset.y * window.devicePixelRatio
        );
        this.classList.add("dragging");
        el.classList.add("dragging");
        this.dispatchEvent(new CustomEvent("begindrag", { detail: { data } }));
    }

    private _dragenter(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLElement).classList.add("dragover");
    }

    private _dragover(e: DragEvent) {
        e.preventDefault();
        if (!isSafari) {
            e.dataTransfer!.dropEffect = !e.altKey ? "link" : "copy";
        }
    }

    private _dragleave(e: DragEvent) {
        (e.target as HTMLElement).classList.remove("dragover");
    }

    private _renderEmployeeHeader({ employee, timeBalance }: EmployeeData) {
        return html`
            <div
                class="employee-header horizontal start-aligning layout"
                @click=${() => this.dispatchEvent(new CustomEvent("header-clicked"))}
            >
                <div>
                    <ptc-avatar class="small" .employee=${employee}></ptc-avatar>
                </div>

                <div class="stretch" style="padding: 0.35em 0.35em 0.35em 0;">
                    <div class="small employee-name ellipsis">${employee.name}</div>

                    ${employee.status === EmployeeStatus.Active
                        ? html`
                              <ptc-progress
                                  .actual=${timeBalance.actual}
                                  .nominal=${timeBalance.nominal}
                                  class="tiny noprint"
                                  .format=${(n: number) => toDurationString(n, true)}
                              ></ptc-progress>
                          `
                        : html`
                              <div
                                  class="employee-status tiny inverted pill fill-horizontally text-centering ${employeeStatusColor(
                                      employee.status
                                  )}"
                              >
                                  ${employeeStatusLabel(employee.status)}
                              </div>
                          `}
                </div>

                <div class="employee-move-buttons">
                    <button
                        @click=${(e: Event) => {
                            e.stopPropagation();
                            this.dispatchEvent(new CustomEvent("move-up"));
                        }}
                        ?disabled=${!this.canMoveUp}
                    >
                        <i class="caret-up"></i>
                    </button>
                    <button
                        @click=${(e: Event) => {
                            e.stopPropagation();
                            this.dispatchEvent(new CustomEvent("move-down"));
                        }}
                        ?disabled=${!this.canMoveDown}
                    >
                        <i class="caret-down"></i>
                    </button>
                </div>
            </div>
        `;
    }

    private _renderTimeEntry({
        entry,
        department,
        date,
        blocked,
        stackSize,
        availabilities,
    }: {
        entry: TimeEntry;
        department: Department;
        date: string;
        blocked?: boolean;
        stackSize?: number;
        availabilities?: Availability[];
    }) {
        const departmentId = (entry.position && entry.position.departmentId) || department.id;
        const otherDep = !!entry.position && entry.position.departmentId !== department.id;
        const allowDrag = !otherDep && !blocked;
        const issues = this.issues.filter((issue) => issue.timeEntries.some((e) => e.id === entry.id));
        const isActive = (this.activeEntry === entry.id && this.activeDepartment === department.id) || entry.preview;

        return app.settings.rosterDisplayMode === "minimal"
            ? html`
                  <div
                      class="minimal-entry small half-padded text-centering ${otherDep
                          ? "colored-text"
                          : "background box"} ${isActive ? "inverted" : ""}"
                      style="--color-highlight: ${app.getTimeEntryColor(entry)};"
                      @click=${(e: MouseEvent) => {
                          e.stopPropagation();
                          this.dispatchEvent(
                              new CustomEvent("select", {
                                  detail: {
                                      date,
                                      employee: entry.employeeId,
                                      department: departmentId,
                                      entry: entry.id,
                                  },
                              })
                          );
                      }}
                  >
                      <i class="${app.localized.timeEntryTypeIcon(entry.type)}"></i>
                  </div>
              `
            : html`
                  <ptc-roster-entry
                      id="entry-${entry.id}-${departmentId}"
                      draggable="${allowDrag ? "true" : "false"}"
                      .entry=${entry}
                      .error=${!!issues.length}
                      .department=${department}
                      .venue=${this.venue}
                      .stackSize=${stackSize}
                      .condensed=${app.settings.rosterDisplayMode === "compact"}
                      class="tiny ${isActive ? "selected" : ""}"
                      @remove=${() => this.dispatchEvent(new CustomEvent("remove-entry", { detail: entry }))}
                      @dragstart=${(e: DragEvent) => this._dragstart(e, { entry })}
                      @select=${({ detail: { field } }: CustomEvent<{ field: string }>) =>
                          this.dispatchEvent(
                              new CustomEvent("select", {
                                  detail: {
                                      date,
                                      employee: entry.employeeId,
                                      department: departmentId,
                                      entry: entry.id,
                                      field,
                                  },
                              })
                          )}
                      style="margin-top: ${Math.min(stackSize || 1, 3) + 2}px; opacity: 0;"
                      .animated=${true}
                      .availabilities=${availabilities}
                  ></ptc-roster-entry>
              `;
    }

    private _renderDay(dep: DepartmentData, emp: EmployeeData, day: DayData) {
        const { types } = this.activeTab;
        return day.absence && (!types || types.includes(day.absence.type))
            ? html`
                  <div
                      class=${classMap({
                          "employee-day": true,
                          blocked: day.blocked,
                          today: day.today,
                          active:
                              day.date === this.activeDate &&
                              day.employee === this.activeEmployee &&
                              dep.department.id === this.activeDepartment,
                          sunday: new Date(day.date).getDay() === 0,
                          holiday: !!day.holiday,
                      })}
                      ?disabled=${!app.hasPermission("manage.employees.absences")}
                  >
                      <div
                          class="absence fullbleed centering layout click ${day.absence.start === day.date
                              ? "absence-start"
                              : ""} ${day.absence.end === dateAdd(day.date, { days: 1 }) ? "absence-end" : ""}"
                          style="--color-highlight: ${timeEntryTypeColor(day.absence.type)}"
                          @click=${() =>
                              this.dispatchEvent(
                                  new CustomEvent("edit-absence", { detail: { absence: day.absence! } })
                              )}
                      >
                          ${day.entries.some((e) => e.type === day.absence!.type)
                              ? html` <i class="big ${app.localized.timeEntryTypeIcon(day.absence.type)}"></i> `
                              : ""}
                      </div>
                  </div>
              `
            : html`
                  <div
                      class=${classMap({
                          "employee-day": true,
                          blocked: day.blocked,
                          today: day.today,
                          active:
                              day.date === this.activeDate &&
                              day.employee === this.activeEmployee &&
                              dep.department.id === this.activeDepartment,
                          sunday: new Date(day.date).getDay() === 0,
                          holiday: !!day.holiday,
                      })}
                      @click=${() =>
                          this.dispatchEvent(
                              new CustomEvent("select", {
                                  detail: {
                                      employee: day.employee,
                                      department: dep.department.id,
                                      date: day.date,
                                      entry: null,
                                  },
                              })
                          )}
                      @dragenter=${this._dragenter}
                      @dragleave=${this._dragleave}
                      @dragover=${this._dragover}
                      @drop=${(e: DragEvent) => {
                          if (!day.blocked) {
                              e.preventDefault();
                              this.dispatchEvent(
                                  new CustomEvent("drop-into-day", {
                                      detail: {
                                          dragEvent: e,
                                          employee: emp.employee,
                                          department: dep.department,
                                          date: day.date,
                                      },
                                  })
                              );
                          }
                      }}
                      ?disabled=${day.readonly}
                  >
                      <button
                          ?hidden=${day.blocked || !!day.entries.length || !!day.availabilities.length}
                          class="transparent add-button ${this.activeDepartment === dep.department.id &&
                          this.activeDate === day.date &&
                          this.activeEmployee === day.employee &&
                          this.activeEntry === "new"
                              ? "selected"
                              : ""}"
                          @click=${(e: Event) => {
                              e.stopPropagation();
                              this.dispatchEvent(
                                  new CustomEvent("select", {
                                      detail: {
                                          employee: day.employee,
                                          department: dep.department.id,
                                          date: day.date,
                                          entry: "new",
                                      },
                                  })
                              );
                          }}
                      >
                          <i class="plus"></i>
                      </button>

                      ${day.blocked
                          ? html`
                                <div class="fullbleed centering layout blocked-reason">
                                    <div class="ellipsis stretch padded-light" title="${day.blockedReason}">
                                        ${day.blockedReason}
                                    </div>
                                </div>
                            `
                          : day.availabilities.length && app.settings.rosterDisplayAvailabilities
                            ? this._renderAvailabilities(day)
                            : emp.employee.isBirthDay(day.date)
                              ? html`
                                    <div class="fullbleed centering layout faded">
                                        <i class="birthday-cake big faded"></i>
                                    </div>
                                `
                              : ""}
                      ${day.entries.map((entry) =>
                          this._renderTimeEntry({ entry, department: dep.department, ...day })
                      )}
                  </div>
              `;
    }

    private _renderAvailabilities(day: DayData) {
        const avs = day.availabilities;
        return app.settings.rosterDisplayMode === "minimal"
            ? html`
                  <div class="fullbleed availabilities">
                      ${avs[0]
                          ? this._renderAvailabilityMinimal(
                                avs[0],
                                avs[0].start || avs[0].end ? "cutoff-bottomright" : ""
                            )
                          : ""}
                      ${avs[1]
                          ? this._renderAvailabilityMinimal(avs[1], avs[1].start || avs[1].end ? "cutoff-topleft" : "")
                          : ""}
                  </div>
              `
            : this._renderAvailabilitiesFull(day.availabilities);
    }

    private _renderAvailabilityMinimal(av: Availability, cutoff: "" | "cutoff-bottomright" | "cutoff-topleft") {
        return html`
            <div class="stretching">
                ${keyed(
                    av.id,
                    html`<div
                        class="fullbleed click centering layout availability-icon ${cutoff}"
                        style="--color-highlight: ${av.color}"
                        ${av.comment
                            ? popover(
                                  html`<div class="subtle smaller">
                                      <div class="bold">${av.fullLabel}</div>
                                      <p><i class="comment"></i>${av.comment}</p>
                                  </div>`,
                                  {
                                      trigger: "hover",
                                      alignment: "bottom",
                                  }
                              )
                            : ""}
                    >
                        <div class="fullbleed click centering layout availability-icon">
                            <i class="${av.icon}"></i>
                        </div>
                    </div>`
                )}
            </div>
        `;
    }

    private _renderAvailabilitiesFull(avs: Availability[]) {
        return html`
            <div class="fullbleed vertical stretching layout availabilities">
                ${avs.map(
                    (av) => html`
                        ${keyed(
                            av.id,
                            html`<div
                                class="click stretch relative centering layout availability"
                                style="--color-highlight: ${av.color};"
                                ${av.comment
                                    ? popover(
                                          html`<div class="subtle smaller">
                                              <div class="bold">${av.fullLabel}</div>
                                              <p><i class="comment"></i>${av.comment}</p>
                                          </div>`,
                                          {
                                              trigger: "hover",
                                              alignment: "bottom",
                                          }
                                      )
                                    : ""}
                            >
                                <div class="smaller colored-text">
                                    <i class="${av.icon}"></i> ${av.label}
                                    ${av.comment ? html`<i class="comment"> </i>` : ""}
                                </div>
                            </div>`
                        )}
                    `
                )}
            </div>
        `;
    }

    private _renderOvertimeBefore() {
        return html`
            <div class="balance-before" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                <ptc-balance
                    .value=${this.employee.timeBalance.reset ?? this.employee.timeBalance.carry}
                    .format=${(val: number) => (val >= 10 ? Math.round(val) : toDurationString(val, true))}
                ></ptc-balance>
            </div>
        `;
    }

    private _renderOvertimeAfter() {
        return html`
            <div class="balance-after" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                <ptc-balance
                    .value=${this.employee.timeBalance.balance}
                    .format=${(val: number) => (val >= 10 ? Math.round(val) : toDurationString(val, true))}
                ></ptc-balance>
            </div>
        `;
    }

    private _render() {
        return html`
            ${this._renderEmployeeHeader(this.employee)} ${this._renderOvertimeBefore()}
            ${this.days.map((day) => this._renderDay(this.department, this.employee, day))}
            ${this._renderOvertimeAfter()}
        `;
    }

    render() {
        return html`${cache(this.isVisible ? this._render() : "")}`;
    }
}

@customElement("ptc-roster")
export class Roster extends Routing(StateMixin(LitElement)) {
    routePattern = /^roster/;

    get routeTitle() {
        return this._venue ? `Dienstplan - ${this._venue.name}` : "Dienstplan";
    }

    get helpPage() {
        return "/handbuch/dienstplan";
    }

    protected defaultRange = () => getRange(new Date(), "week");
    protected rangeChanged = () => this.synchronize(true);

    private get _venue() {
        return this._activeTab && app.getVenue(this._activeTab.venue);
    }

    @routeProperty({ type: Number, param: "tab", default: () => 0 })
    private _activeTabIndex: number;

    private get _activeTab() {
        return app.rosterTabs[this._activeTabIndex];
    }

    @state()
    private _employees: EmployeeData[] = [];

    @state()
    private _data: RosterData;

    @state()
    private _filterString = "";

    @state()
    private _timeEntries: TimeEntry[] = [];

    @state()
    private _previousTimeEntries: TimeEntry[] = [];

    @state()
    private _dailyResults: DailyResults[] = [];

    @state()
    private _issues: Issue[] = [];

    @state()
    private _loading: boolean = false;

    @state()
    private _previewRosterTemplate: RosterTemplate | null = null;

    @state()
    private _previewTimeEntries: TimeEntry[] | null = null;

    @state()
    private _targets: RosterTargets[] = [];

    @state()
    private _absences: Absence[] = [];

    @state()
    private _availabilities: Availability[] = [];

    @state()
    private _activeDate: DateString | null = null;

    @state()
    private _activeEmployee: number | null = null;

    @state()
    private _activeDepartment: number | null = null;

    @state()
    private _activeEntry: string | null = null;

    @state()
    private _activeField: string | null = null;

    @state()
    private _displayAutoAssignMenu = false;

    @state()
    private _dragData: DragData | null = null;

    @state()
    private _rosterNotes: RosterNote[] = [];

    @state()
    private _rosterNoteLanes: RosterNote[][] = [];

    @state()
    private _publicUrlPromise: Promise<string> = new Promise<string>(() => {});

    @state()
    private _frequentTimes: ShiftTemplate[] = [];

    @state()
    private _collapsed = new Set<number>();

    @state()
    private _collapsedSuggestions = new Set<number>();

    @query(".main")
    private _main: HTMLDivElement;

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query("#rosterTemplatesPopover")
    private _rosterTemplatesPopover: Popover;

    @query("#clearRosterDialog")
    private _clearRosterDialog: Dialog<void, void>;

    @query("ptc-employee-day")
    private _employeeDayForm: EmployeeDay;

    @query("#issuePopover")
    private _issuePopover: Popover;

    @queryAll("ptc-roster-targets")
    private _rosterTargetsElements: RosterTargetsElement[];

    @query("ptc-roster-costs")
    private _rosterCosts: RosterCosts;

    @query("ptc-auto-assign-menu")
    private _autoAssignMenu: AutoAssignMenu;

    @singleton("ptc-publish-roster-dialog")
    private _publishRosterDialog: PublishRosterDialog;

    @singleton("ptc-absence-dialog")
    private _absenceDialog: AbsenceDialog;

    @singleton("ptc-roster-note-popover")
    private _rosterNotePopover: RosterNotePopover;

    @singleton("ptc-send-message-dialog")
    private _sendMessageDialog: SendMessageDialog;

    @singleton("ptc-availability-dialog")
    private _availabilityDialog: AvailabilityDialog;

    @singleton("ptc-create-roster-template-dialog")
    private _createRosterTemplateDialog: CreateRosterTemplateDialog;

    private _resizeObserver?: ResizeObserver;

    private get _departments(): Department[] {
        return this._venue?.departments.filter((department) => app.hasAccess({ department })) || [];
    }

    private get _filteredDepartments(): Department[] {
        return this._departments.filter(
            (d) => !this._activeTab.departments || this._activeTab.departments.includes(d.id)
        );
    }

    private get _publicUrlWithFilters(): Promise<string> {
        return this._publicUrlPromise.then((url) => {
            if (app.settings.rosterMirrorDepartments) {
                url += "&md=1";
            }

            const deps = this._activeTab.departments;
            return !deps || !deps.length || deps.length === this._venue!.departments.length
                ? url
                : `${url}&d=${deps.join(",")}`;
        });
    }

    private get _unpublishedEntries() {
        if (!this.dateRange) {
            return [];
        }
        const { from, to } = this.dateRange;
        const positions = this._filteredDepartments.flatMap((d) =>
            [...d.positions, ...d.archivedPositions].map((p) => p.id)
        );
        return this._timeEntries.filter(
            (e) =>
                (e.position
                    ? positions.includes(e.position.id)
                    : e.employeeId
                      ? app.getEmployee(e.employeeId)?.positions.some((p) => positions.includes(p.id))
                      : true) &&
                e.date >= from &&
                e.date < to &&
                !e.isPast &&
                (!this._activeTab.time || e.isWithin(this._activeTab.time)) &&
                !e.isPublished
        );
    }

    private get _unpublishedCount() {
        return this._unpublishedEntries.length;
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("dragover", (e: DragEvent) => this._dragover(e));
        this.addEventListener("drop", (e: DragEvent) => e.preventDefault());
        this.addEventListener("dragend", (e: DragEvent) => this._dragend(e));
        document.addEventListener("keydown", (e: KeyboardEvent) => {
            if (
                !this.active ||
                e.ctrlKey ||
                e.metaKey ||
                (!this._employeeDayForm?.matches(":focus-within") && isCursorInInput()) ||
                (this._displayAutoAssignMenu && e.key !== "Escape")
            ) {
                return;
            }
            const direction = { w: "up", s: "down", a: "left", d: "right" }[e.key] as "up" | "down" | "left" | "right";
            if (e.shiftKey && e.key.toLowerCase() === "a") {
                this.go(null, { ...this.router.params, date: dateAdd(this.date, { days: -7 }) });
                if (this._activeDate) {
                    this._setActive({ date: dateAdd(this._activeDate, { days: -7 }) });
                }
            } else if (e.shiftKey && e.key.toLowerCase() === "d") {
                this.go(null, { ...this.router.params, date: dateAdd(this.date, { days: 7 }) });
                if (this._activeDate) {
                    this._setActive({ date: dateAdd(this._activeDate, { days: 7 }) });
                }
            } else if (direction) {
                this._moveCursor(direction);
                e.preventDefault();
            } else if (e.key === "Escape") {
                if (this._displayAutoAssignMenu) {
                    this._autoAssignMenu?.close();
                }
                this._setActive(
                    { ...this.router.params, date: null, employee: null, department: null, entry: null },
                    true
                );
            } else if (e.shiftKey && e.key === "Backspace") {
                const activeEntry = this._timeEntries.find((e) => e.id === this._activeEntry);
                if (activeEntry) {
                    this._removeTimeEntry(activeEntry);
                }
            } else if (e.key === "n") {
                this._setActive({ entry: "new" });
            }
        });
    }

    private _moveCursor(direction: "up" | "down" | "left" | "right") {
        let date: DateString | undefined = undefined;
        let department: number | undefined = undefined;
        let employee: number | undefined = undefined;
        let entry: string | null | undefined = undefined;
        let nextMove: "up" | "down" | "right" | "left" | undefined = undefined;

        if (direction === "up" || direction === "down") {
            const diff = direction === "up" ? -1 : direction === "down" ? 1 : 0;
            date = this._activeDate || this._data.dates[0];

            const depIndex = this._data.departments.findIndex((d) => d.department.id === this._activeDepartment);
            let dep = this._data.departments[depIndex] || this._data.departments[0];
            const empIndex = dep.employees.findIndex((e) => e.employeeData.employee.id === this._activeEmployee);
            let emp = dep.employees[empIndex] || dep.employees[0];
            let day = emp.days.find((d) => d.date === date)! || emp.days[0];
            const entryIndex = day.entries.findIndex((e) => e.id === this._activeEntry);

            let newDepIndex = depIndex;
            let newEntryIndex = entryIndex + diff;

            if (newEntryIndex < 0) {
                emp = dep.employees[empIndex - 1];
                if (!emp) {
                    do {
                        newDepIndex = newDepIndex ? newDepIndex - 1 : this._data.departments.length - 1;
                        dep = this._data.departments[newDepIndex];
                        emp = dep && dep.employees[dep.employees.length - 1];
                    } while ((!emp || this._collapsed.has(dep.department.id)) && newDepIndex !== depIndex);
                }
                if (!emp) {
                    return;
                }
                day = emp.days.find((d) => d.date === date)!;
                newEntryIndex = day.entries.length - 1;
            } else if (newEntryIndex > day.entries.length - 1) {
                emp = dep.employees[empIndex + 1];
                if (!emp) {
                    do {
                        newDepIndex = newDepIndex >= this._data.departments.length - 1 ? 0 : newDepIndex + 1;
                        dep = this._data.departments[newDepIndex];
                        emp = dep && dep.employees[0];
                    } while ((!emp || this._collapsed.has(dep.department.id)) && newDepIndex !== depIndex);
                }
                if (!emp) {
                    return;
                }
                day = emp.days.find((d) => d.date === date)!;
                newEntryIndex = 0;
            }

            department = dep.department.id;
            employee = emp.employeeData.employee.id;

            const e = day.entries[newEntryIndex];
            entry = (e && e.id) || null;
        } else {
            const diff = direction === "left" ? -1 : direction === "right" ? 1 : 0;
            const firstDate = this._data.dates[0];
            const lastDate = this._data.dates[this._data.dates.length - 1];
            const activeDate = this._activeDate || dateAdd(this._data.dates[0], { days: -1 });
            date = dateAdd(activeDate, { days: diff });
            if (date > lastDate) {
                date = firstDate;
                nextMove = "down";
            } else if (date < firstDate) {
                date = lastDate;
                nextMove = "up";
            }
            entry = null;
            if (!this._activeDepartment || !this._activeEmployee) {
                department = this._data.departments[0].department.id;
                employee = this._data.departments[0].employees[0].employeeData.employee.id;
            }
        }

        this._setActive(
            {
                date,
                department,
                employee,
                entry,
                field: "start",
            },
            true
        );

        if (nextMove) {
            this._moveCursor(nextMove);
        }
    }

    private _rowsObserver?: IntersectionObserver;
    private _departmentsObserver: IntersectionObserver;

    private _rowsIntersectionHandler(entries: IntersectionObserverEntry[]) {
        entries.forEach((e) => ((e.target as RosterRow).isVisible = e.isIntersecting));
    }

    private _departmentsIntersectionHandler(entries: IntersectionObserverEntry[]) {
        entries.forEach((e) => {
            const id = Number((e.target as HTMLDivElement).dataset.department);
            if (e.isIntersecting) {
                this._collapsedSuggestions.delete(id);
            } else {
                this._collapsedSuggestions.add(id);
            }
        });
        this.requestUpdate();
    }

    private _observeRows() {
        if (!this._rowsObserver) {
            const main = this.renderRoot.querySelector(".main");
            if (!main) {
                return;
            }
            this._rowsObserver = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => this._rowsIntersectionHandler(entries),
                { root: main, rootMargin: "50%" }
            );
        }
        const elements = this.renderRoot.querySelectorAll("ptc-roster-row");
        for (const el of elements) {
            this._rowsObserver.observe(el);
        }
    }

    private _observeDepartments() {
        if (!this._departmentsObserver) {
            const main = this.renderRoot.querySelector(".main");
            if (!main) {
                return;
            }
            this._departmentsObserver = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => this._departmentsIntersectionHandler(entries),
                { root: main, rootMargin: "-110px" }
            );
        }
        const elements = this.renderRoot.querySelectorAll(".department");
        for (const el of elements) {
            this._departmentsObserver.observe(el);
        }
    }

    private _updatePublicUrl() {
        this._publicUrlPromise = app.api
            .getPublicRosterUrl(new GetPublicRosterUrlParams({ venue: this._venue!.id, ...this.dateRange }))
            .then((res) => res.url);
    }

    async updated(changes: Map<string, any>) {
        if (!this.active) {
            return;
        }
        if (
            (changes.has("dateFrom") ||
                changes.has("dateTo") ||
                changes.has("active") ||
                changes.has("_activeTabIndex")) &&
            this.dateRange &&
            this.active
        ) {
            this.synchronize(true);
        }

        if (changes.has("_timeEntries") || changes.has("_prevTimeEntries")) {
            this._updateFrequentTimes();
        }

        if (!this._resizeObserver && this._main) {
            this._resizeObserver = new ResizeObserver(debounce(() => this._resized(), 200));
            this._resizeObserver.observe(this._main);
        }

        this._resized();
        this._observeRows();
        this._observeDepartments();
        await wait(20);
        for (const row of this.renderRoot.querySelectorAll<HTMLDivElement>(".unassigned-row")) {
            row.style.height = row.querySelector<HTMLDivElement>(".employee-row")!.offsetHeight + "px";
        }

        if (changes.has("_dragData") && this._dragData?.shiftTemplate?.positionId) {
            const availableRows = [
                ...this.renderRoot.querySelectorAll("ptc-roster-row:not([disabled])"),
            ] as RosterRow[];
            if (availableRows.length && !availableRows.some((row) => row.isVisible)) {
                this._main.scrollTop = availableRows[0].offsetTop - 200;
            }
        }
    }

    private _resized() {
        this._updateRosterNoteStyles();
        this._updateDepartmentHeaderStyles();
    }

    pageFocused() {
        if (this.active) {
            if (this._displayAutoAssignMenu) {
                this._autoAssignMenu.close();
            }
            this.synchronize(true);
        }
    }

    async synchronize(showSpinner = false) {
        if (!this._venue || !this.dateRange) {
            return;
        }

        this._setActive({ employee: null, department: null, date: null, entry: null });

        if (showSpinner) {
            this._loading = true;
        }

        try {
            await app.syncTimeEntries();

            const { from, to } = this.dateRange;
            const currMonth = getRange(from, "month");
            const nextMonth = getRange(to, "month");
            const twoWeeksPrior = dateAdd(this.dateRange.from, { days: -14 });
            const entriesFrom = twoWeeksPrior < currMonth.from ? twoWeeksPrior : currMonth.from;

            const start = Date.now();

            const worksInDepartment = (e: Employee, departmentId: number) =>
                e.positions.some((p) => p.departmentId === departmentId);

            const venueEmployeeIds = app.employees
                .filter((e) =>
                    this._venue?.departments
                        .filter((department) => app.hasAccess({ department }))
                        .some((d) => worksInDepartment(e, d.id))
                )
                .map((e) => e.id);

            const [targets, absences, notes, availabilites, dailyResults] = await Promise.all([
                app.api
                    .getRosterTargets(
                        new GetRosterTargetsParams({
                            date: this.dateFrom,
                            venue: this._venue.id,
                        })
                    )
                    .finally(() => console.log("getRosterTargets", Date.now() - start)),
                app.api
                    .getAbsences(
                        new GetAbsencesParams({
                            from,
                            to,
                            employee: venueEmployeeIds,
                        })
                    )
                    .finally(() => console.log("getAbsences", Date.now() - start)),
                app.api
                    .getRosterNotes(
                        new GetRosterNotesParams({
                            from,
                            to,
                        })
                    )
                    .finally(() => console.log("getRosterNotes", Date.now() - start)),
                app.api
                    .getAvailabilities(
                        new GetAvailabilitesParams({
                            from,
                            to,
                            employees: venueEmployeeIds,
                        })
                    )
                    .finally(() => console.log("getAvailabilities", Date.now() - start)),
                app.api
                    .getDailyResults(
                        new GetDailyResultsParams({
                            from,
                            to: dateAdd(to, { days: 1 }),
                            filters: venueEmployeeIds.map((id) => ({ type: "employeeId", value: id })),
                            planned: true,
                        })
                    )
                    .finally(() => console.log("getDailyResults", Date.now() - start)),
            ]);

            const entries = await app
                .getTimeEntries({
                    from: entriesFrom,
                    to: nextMonth.to,
                    type: [
                        TimeEntryType.Work,
                        TimeEntryType.Vacation,
                        TimeEntryType.Sick,
                        TimeEntryType.Free,
                        TimeEntryType.CompDay,
                        TimeEntryType.ChildSick,
                        TimeEntryType.SickInKUG,
                        TimeEntryType.HourAdjustment,
                    ],
                    includeDeleted: true,
                    includeUnassigned: true,
                    employee: venueEmployeeIds,
                })
                .finally(() => console.log("getTimeEntries", Date.now() - start));

            app.getTimeEntries({
                from: dateAdd(entriesFrom, { days: -14 }),
                to: entriesFrom,
                type: [
                    TimeEntryType.Work,
                    TimeEntryType.Vacation,
                    TimeEntryType.Sick,
                    TimeEntryType.Free,
                    TimeEntryType.CompDay,
                    TimeEntryType.ChildSick,
                    TimeEntryType.SickInKUG,
                    TimeEntryType.HourAdjustment,
                ],
                includeDeleted: true,
                includeUnassigned: true,
                employee: venueEmployeeIds,
            }).then((entries) => (this._previousTimeEntries = entries));

            console.log("all loaded", Date.now() - start);

            this._updatePublicUrl();

            this._timeEntries = entries;
            this._targets = targets;
            this._absences = absences;
            this._rosterNotes = notes;
            this._availabilities = availabilites;
            this._dailyResults = dailyResults;
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this.refresh();

        if (showSpinner) {
            this._loading = false;
        }
    }

    refresh() {
        if (!this._venue || !this.dateRange) {
            return;
        }
        const { from, to } = this.dateRange;
        const today = toDateString(new Date());

        const data: RosterData = {
            dates: [],
            departments: [],
            holidays: new Map<string, Holiday | null>(),
        };

        let date = from;
        while (date < to) {
            data.dates.push(date);
            const holiday = getHolidayForDate(date, {
                holidays: this._venue.enabledHolidays,
                country: app.company!.country,
            });
            if (holiday) {
                data.holidays.set(date, holiday);
            }
            date = dateAdd(date, { days: 1 });
        }

        const { time, types } = this._activeTab;
        this._previewTimeEntries =
            (this._previewRosterTemplate &&
                this._getTimeEntriesForTemplate(this._previewRosterTemplate, this.dateRange)) ||
            [];

        this._employees = app.employees
            .map((employee) => {
                const contract = employee.contracts.find((c) => c.start <= to && (!c.end || c.end > from));
                if (!contract) {
                    return null;
                }

                const entries = this._timeEntries.filter(
                    (a) => a.employeeId === employee.id && a.date >= from && a.date < to && !a.deleted
                );

                const dailyResults: DailyResults =
                    this._dailyResults.find((b) => b.employeeId === employee.id) || new DailyResults();

                const timeBalance = {
                    employeeId: employee.id,
                    from,
                    to,
                    actual: 0 as Hours,
                    nominal: 0 as Hours,
                    reset: dailyResults.timeBalancePlanned?.reset,
                    carry: dailyResults.timeBalancePlanned?.carry || (0 as Hours),
                    adjustments: 0 as Hours,
                    balance: 0 as Hours,
                    absences: 0 as Hours,
                    work: 0 as Hours,
                    difference: 0 as Hours,
                };

                timeBalance.nominal = getNominalTime(app.company!, employee, { from, to });
                timeBalance.actual = entries.reduce((total, entry) => {
                    const result =
                        entry.type === TimeEntryType.Work
                            ? getTimeResult(app.company!, employee, entry, makeTimeResult(), 1, "mixed")
                            : entry.result;
                    return add(total, result?.base.duration || (0 as Hours));
                }, 0 as Hours);
                timeBalance.difference = subtract(timeBalance.actual, timeBalance.nominal);
                timeBalance.balance = add(timeBalance.reset ?? timeBalance.carry, timeBalance.difference);

                const empData: EmployeeData = {
                    employee,
                    contract,
                    timeBalance,
                };

                return empData;
            })
            .filter((e) => !!e) as EmployeeData[];

        for (const department of this._departments) {
            const depData: DepartmentData = {
                department,
                employees: [],
                unassigned: data.dates.map((date) => {
                    return {
                        date,
                        entries: [...this._timeEntries, ...(this._previewTimeEntries || [])]
                            .filter(
                                (e) =>
                                    e.date === date &&
                                    e.position?.departmentId === department.id &&
                                    !e.employeeId &&
                                    !e.deleted &&
                                    (!time || e.isWithin(time)) &&
                                    (e.type === TimeEntryType.Work || !types || types.includes(e.type))
                            )
                            .sort(
                                (a, b) =>
                                    (a.startPlanned ? Number(a.start) : Infinity) -
                                    (b.startPlanned ? Number(b.start) : Infinity)
                            ),
                    };
                }),
            };

            for (const employee of app.getEmployeesForDepartment(department)) {
                const commitBefore = app.company?.settings.commitTimeEntriesBefore;
                const empData = this._employees.find((e) => e.employee.id === employee.id);
                if (!empData) {
                    continue;
                }
                const entries = [...this._timeEntries, ...(this._previewTimeEntries || [])].filter(
                    (a) => a.employeeId === employee.id && a.date >= from && a.date < to
                );
                const days: DayData[] = [];

                for (const date of data.dates) {
                    const holiday = data.holidays.get(date) || null;
                    const contract = employee.getContractForDate(date);
                    const todaysEntries = entries
                        .filter(
                            (a) =>
                                a.date === date &&
                                !a.deleted &&
                                (app.settings.rosterMirrorDepartments ||
                                    !a.position ||
                                    a.position.departmentId === department.id) &&
                                (!time || a.isWithin(time)) &&
                                a.type !== TimeEntryType.HourAdjustment &&
                                (a.type === TimeEntryType.Work || !types || types.includes(a.type))
                        )
                        .sort(
                            (a, b) =>
                                (a.startPlanned ? Number(a.start) : Infinity) -
                                (b.startPlanned ? Number(b.start) : Infinity)
                        );
                    const absence = this._absences.find(
                        (a) =>
                            a.employeeId === employee.id &&
                            a.start <= date &&
                            a.end > date &&
                            (a.status === AbsenceStatus.Approved || a.status === AbsenceStatus.Inferred)
                    );
                    const isPast = date < today;
                    days.push({
                        employee: employee.id,
                        date,
                        blocked: !contract || contract.blocked,
                        blockedReason: (contract && contract.comment) || "Inaktiv",
                        today: date === today,
                        entries: todaysEntries,
                        isPast,
                        readonly:
                            (!!commitBefore && date < commitBefore) ||
                            (isPast && !app.hasPermission("manage.employees.time")),
                        absence,
                        availabilities: this._availabilities
                            .filter((a) => a.employeeId === employee.id && a.date === date)
                            .sort((a, b) => ((a.start || "") < (b.start || "") ? -1 : 1)),
                        holiday,
                    });
                }

                depData.employees.push({
                    employeeData: empData,
                    days,
                });
            }

            data.departments.push(depData);
        }

        this._data = data;
        this._issues = app.employees
            .flatMap((e) => getIssues(e, app.company!, this._timeEntries, { from, to: dateAdd(to, { days: 1 }) }))
            .filter((issue) => !issue.ignored);
        this._employeeDayForm && this._employeeDayForm.updateForms();

        this._rosterCosts && this._rosterCosts.updateConfig();
        for (const t of this._rosterTargetsElements) {
            t.requestUpdate();
        }

        this._updateRosterNoteLanes();
        this._loadDayStats();
    }

    private _loadDayStats = debounce(() => this._employeeDayForm?.loadStats(), 500);

    private _updateRosterNoteLanes() {
        const rosterNoteLanes: RosterNote[][] = [];
        for (const note of this._rosterNotes.filter((note) => note.venueId === this._venue!.id)) {
            if (
                note.departments &&
                this._activeTab.departments &&
                !note.departments.some((d) => this._activeTab.departments!.includes(Number(d)))
            ) {
                continue;
            }
            let lane = rosterNoteLanes.find((notes) => !notes.some((n) => n.start < note.end && n.end > note.start));
            if (!lane) {
                lane = [];
                rosterNoteLanes.push(lane);
            }
            lane.push(note);
        }
        this._rosterNoteLanes = rosterNoteLanes;
    }

    stateChanged() {
        if (this.active) {
            this.debouncedRefresh();
        }
    }

    debouncedRefresh = debounce(() => this.refresh(), 100);

    private _updateFilter() {
        this._filterString = this._filterInput.value;
    }

    private _availablePositions(employee: Employee, dep: Department) {
        return employee.positions
            .filter((position) => position.active && position.departmentId === dep.id)
            .sort((a, b) => a.order - b.order);
    }

    private async _addTimeEntry(vals: Partial<TimeEntry>, setActive = false) {
        if (!vals.employeeId && vals.type !== TimeEntryType.Work) {
            return;
        }

        if (![TimeEntryType.Work, TimeEntryType.CompDay, TimeEntryType.Free].includes(vals.type!)) {
            if (!app.hasPermission("manage.employees.absences")) {
                alert("Sie haben keine ausreichenden Berechtigungen für diese Aktion!", {
                    type: "warning",
                    title: "Fehlende Berechtigung",
                });
                return;
            }

            const absence = new Absence({
                type: vals.type,
                employeeId: vals.employeeId!,
                start: vals.date,
                end: dateAdd(vals.date!, { days: 1 }),
            });

            return this._editAbsence(absence);
        }

        const entry = new TimeEntry(vals);
        await app.createOrUpdateTimeEntries(entry, { applyAutoMeals: true, otherEntries: this._timeEntries });
        this._timeEntries.push(entry);
        this.refresh();

        if (entry.type === TimeEntryType.CompDay) {
            // to have a correct time balance, we need the .result to be calculated before updating the view
            await this.synchronize(true);
        }

        if (setActive) {
            this._setActive({
                employee: entry.employeeId,
                date: entry.date,
                department: entry.position && entry.position.departmentId,
                entry: entry.id,
                field: "start",
            });
        }
        return;
    }

    private async _removeTimeEntry(entry: TimeEntry) {
        if (entry.isPast && (entry.startFinal || entry.endFinal)) {
            const employee = entry.employeeId && app.getEmployee(entry.employeeId);
            if (!app.hasPermission("manage.employees.time") || (employee && !app.hasPermissionForEmployee(employee))) {
                alert("Sie haben keine Berechtigung für diese Aktion!", { type: "warning" });
                return;
            }
            if (
                !(await confirm("Sind Sie sicher dass Sie diesen Eintrag löschen möchten?", "Löschen", "Abbrechen", {
                    title: "Eintrag Löschen",
                    type: "destructive",
                    icon: "trash",
                    optionsLayout: "horizontal",
                }))
            ) {
                return;
            }
        }

        if (entry.id === this._activeEntry) {
            const activeDep = this._data.departments.find((d) => d.department.id === this._activeDepartment);
            const activeEmp =
                activeDep && activeDep.employees.find((e) => e.employeeData.employee.id === this._activeEmployee);
            const days = activeEmp?.days || activeDep?.unassigned;
            const activeDay = days?.find((d) => d.date === this._activeDate);
            const index = activeDay && activeDay.entries.findIndex((e) => e.id === this._activeEntry);
            const entry =
                (index && activeDay!.entries[index - 1]) || activeDay!.entries.find((e) => e.id !== this._activeEntry);
            this._setActive({ entry: (entry && entry.id) || "new" });
        }
        app.removeTimeEntries(entry);
        if (!entry.published) {
            this._timeEntries = this._timeEntries.filter((e) => e.id !== entry.id);
        }
        this.refresh();
    }

    private _updateFrequentTimes() {
        const templates = new Map<string, ShiftTemplate>(
            app.shiftTemplates.map(({ venue, start, end, positionId }) => [
                `${venue}-${positionId}-${start?.slice(0, 5)}-${end?.slice(0, 5)}`,
                {
                    venue,
                    start,
                    end,
                    uses: Infinity,
                    positionId,
                    favorite: true,
                },
            ])
        );

        const { time, departments } = this._activeTab;

        for (const a of [...this._timeEntries, ...this._previousTimeEntries].filter(
            (a) =>
                a.type === TimeEntryType.Work &&
                !a.deleted &&
                !app.isRemoved(a) &&
                a.planned &&
                a.position &&
                (!departments || !departments.includes(a.position.departmentId)) &&
                (!time || a.isWithin(time))
        )) {
            const { venue, department } = app.getDepartment(a.position!.departmentId);
            if (!venue || !department || !app.hasAccess({ department })) {
                continue;
            }

            const start = toTimeString(a.startPlanned || a.startFinal);
            const end = toTimeString(a.endPlanned || a.endFinal);

            if (!start || !end) {
                continue;
            }

            const key = `${venue.id}-${a.position!.id}-${start}-${end}`;

            if (!templates.has(key)) {
                templates.set(key, { venue: venue.id, start, end, uses: 0, positionId: a.position!.id });
            }

            templates.get(key)!.uses!++;
        }

        this._frequentTimes = [...templates.values()].sort(({ uses: n1 }, { uses: n2 }) => n2! - n1!);

        this.requestUpdate("_frequentTimes");
    }

    private _updateTimeEntry(a: TimeEntry, data: Partial<TimeEntry> = {}) {
        if (app.isRemoved(a)) {
            return;
        }

        Object.assign(a, data);

        app.createOrUpdateTimeEntries(a);
        this.refresh();
    }

    // private _scrollToDepartment({ id }: { id: number }) {
    //     const departmentEl = this.renderRoot.querySelector(`.department[data-department="${id}"]`) as HTMLDivElement;
    //     if (departmentEl) {
    //         this._main.scrollTop = departmentEl.offsetTop - 110;
    //     }
    // }

    private _toggleDepartment({ id }: { id: number }, collapsed: boolean = !this._collapsed.has(id)) {
        if (collapsed) {
            this._collapsed.add(id);
            this._collapsedSuggestions.add(id);
        } else {
            this._collapsed.delete(id);
        }
        this.requestUpdate();
    }

    private _toggleDepartmentSuggestions(
        { id }: { id: number },
        collapsed: boolean = !this._collapsedSuggestions.has(id)
    ) {
        if (collapsed) {
            this._collapsedSuggestions.add(id);
        } else {
            this._collapsedSuggestions.delete(id);
            this._collapsed.delete(id);
            // this._scrollToDepartment({ id });
        }
        this.requestUpdate();
    }

    private dropIntoDay(e: DragEvent, employee: Employee | null, department: Department, date: DateString) {
        e.preventDefault();

        const data = this._dragData;

        if (!data) {
            return;
        }

        let id: string | undefined = undefined;
        let type: TimeEntryType = TimeEntryType.Work;
        let position: Position | null = null;
        let startPlanned: Date | null = null;
        let endPlanned: Date | null = null;
        let startFinal: Date | null = null;
        let endFinal: Date | null = null;
        let breakPlanned: Hours | null = null;

        if (data.entry) {
            id = data.entry.id;
            type = data.entry.type || TimeEntryType.Work;
            position = data.entry.position || null;
            id = data.entry.id;
            [startPlanned, endPlanned] = parseTimes(
                date,
                toTimeString(data.entry.startPlanned),
                toTimeString(data.entry.endPlanned)
            );
            [startFinal, endFinal] = parseTimes(
                date,
                toTimeString(data.entry.startFinal),
                toTimeString(data.entry.endFinal)
            );
            breakPlanned = data.entry.breakPlanned || null;
        } else {
            [startPlanned, endPlanned] = parseTimes(date, data.shiftTemplate?.start, data.shiftTemplate?.end);
            position =
                (data.shiftTemplate?.positionId && app.getPosition(data.shiftTemplate?.positionId)?.position) || null;
            type = data.shiftTemplate?.type || TimeEntryType.Work;

            breakPlanned = data.shiftTemplate?.breakPlanned ? (data.shiftTemplate.breakPlanned as Hours) : null;
        }

        const availablePositions = employee ? this._availablePositions(employee, department) : department.positions;

        if (type === TimeEntryType.Work) {
            if (!position || !availablePositions.some((p) => p.id === position!.id)) {
                position = availablePositions[0];
            }
        } else {
            position = null;
        }

        if (!id || e.altKey) {
            const isPast = date < toDateString(new Date());
            // if the day is in the past and we're adding a new entry,
            // set final times instead of planned
            if (isPast && !startFinal && !endFinal) {
                startFinal = startPlanned;
                endFinal = endPlanned;
                startPlanned = null;
                endPlanned = null;
            }
            this._addTimeEntry(
                {
                    type,
                    employeeId: employee?.id || null,
                    startPlanned,
                    endPlanned,
                    startFinal,
                    endFinal,
                    position,
                    date,
                    breakPlanned,
                },
                this._activeEntry === id || (type === TimeEntryType.Work && !startPlanned)
            );
        } else {
            const entry = this._timeEntries.find((e) => e.id === id);
            if (!entry) {
                return;
            }
            entryPositions.set(`${department.id}_${entry.id}`, {
                x: e.pageX - (data.imageOffset?.x || 0),
                y: e.pageY - (data.imageOffset?.y || 0),
            });

            this._updateTimeEntry(entry, {
                position,
                date,
                employeeId: employee?.id || null,
                startPlanned,
                endPlanned,
                startFinal,
                endFinal,
            });
            if (this._activeEntry === entry.id) {
                this._setActive({
                    date,
                    department: position && position.departmentId,
                    employee: employee?.id || null,
                });
            }
        }

        (e.target as HTMLElement).classList.remove("dragover");

        setTimeout(() => this._dragend(), 50);
    }

    private _dragstart(e: DragEvent, data: DragData) {
        this._dragData = data;
        const el = e.target as HTMLElement;
        const imgEl = (el.shadowRoot && (el.shadowRoot.querySelector(".container") as HTMLElement)) || el;
        const dt = e.dataTransfer!;
        dt.setData("text/plain", "42");
        dt.effectAllowed = "all";
        dt.dropEffect = "move";
        data.imageOffset = { x: imgEl.offsetWidth / 2, y: imgEl.offsetHeight / 2 };
        dt.setDragImage(imgEl, data.imageOffset.x, data.imageOffset.y);
        this.classList.add("dragging");
        el.classList.add("dragging");
    }

    private _dragenter(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLElement).classList.add("dragover");
    }

    private _dragover(e: DragEvent) {
        e.preventDefault();
        if (!isSafari) {
            e.dataTransfer!.dropEffect = !e.altKey ? "link" : "copy";
        }
    }

    private _dragleave(e: DragEvent) {
        (e.target as HTMLElement).classList.remove("dragover");
    }

    private _dragend(e?: DragEvent) {
        this._dragData = null;
        this.classList.remove("dragging");
        for (const el of [this, ...this.renderRoot!.querySelectorAll(".dragging")] as HTMLElement[]) {
            el.classList.remove("dragging");
        }
        e && e.preventDefault();
    }

    private _toggleFavorite(template: ShiftTemplate) {
        const shiftTemplates = [...app.shiftTemplates];
        const existing = shiftTemplates.findIndex(
            (t) =>
                t.venue === template.venue &&
                t.start === template.start &&
                t.end === template.end &&
                t.positionId === template.positionId
        );
        if (existing === -1) {
            template.favorite = true;
            shiftTemplates.push(template);
        } else {
            template.favorite = false;
            shiftTemplates.splice(existing, 1);
        }
        app.shiftTemplates = shiftTemplates;
        this.requestUpdate();
    }

    private async _publishChanges({ detail: { timeEntries } }: CustomEvent<{ timeEntries: TimeEntry[] }>) {
        if (timeEntries.length) {
            const res = await this._publishRosterDialog.show({
                venue: this._venue!,
                entries: this._timeEntries,
                unpublishedEntries: timeEntries,
                absences: this._absences,
                ...this.dateRange!,
            });
            if (res) {
                await this.synchronize(true);
            }
        }
    }

    private async _print() {
        print(await this._publicUrlWithFilters);
    }

    private async _shareViaMessage() {
        const venue = this._venue!;
        const departments = this._filteredDepartments;
        const url = await this._publicUrlWithFilters;
        const from = formatDate(this.dateRange!.from);
        const to = formatDate(dateAdd(this.dateRange!.to, { days: -1 }));
        const entireVenue = !departments || departments.length === venue.departments.length;

        await this._sendMessageDialog.show({
            venues: entireVenue ? [venue] : undefined,
            departments: entireVenue ? undefined : departments,
            message: `Hallo zusammen,

Es ist ein neuer Dienstplan für die Woche vom ${from} bis ${to} verfügbar!
Diesen kannst du über folgenden Link einsehen:

${url}

Liebe Grüße,
${app.profile!.name}`,
        });
    }

    private async _createRosterTemplate() {
        const { time } = this._activeTab;
        this._rosterTemplatesPopover.hide();
        this._createRosterTemplateDialog.show({
            timeEntries: this._timeEntries.filter(
                (a) =>
                    a.date >= this.dateRange!.from &&
                    a.date < this.dateRange!.to &&
                    a.type === TimeEntryType.Work &&
                    !a.deleted &&
                    !app.isRemoved(a) &&
                    a.planned &&
                    a.position &&
                    a.position.active &&
                    (!time || a.isWithin(time))
            ),
            venue: this._venue!,
            departments: this._filteredDepartments,
        });
    }

    private async _deleteRosterTemplate(template: RosterTemplate) {
        const confirmed = await confirm(
            `Wollen Sie die Vorlage "${template.name}" entfernen?`,
            "Entfernen",
            "Abbrechen",
            {
                type: "destructive",
                title: "Vorlage Entfernen",
                optionsLayout: "horizontal",
                icon: "trash",
            }
        );
        if (!confirmed) {
            return;
        }

        this._loading = true;
        try {
            await app.updateVenue(
                new UpdateVenueParams({
                    id: this._venue!.id,
                    rosterTemplates: this._venue!.rosterTemplates.filter((t) => t.id !== template.id),
                })
            );
            this.requestUpdate();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _selectRosterTemplate(template: RosterTemplate | null) {
        this._rosterTemplatesPopover.hide();
        this._previewRosterTemplate = template;
        this.refresh();
    }

    private async _applyRosterTemplate() {
        if (!this._previewRosterTemplate || !this._previewTimeEntries) {
            return;
        }

        const entries = this._previewTimeEntries;
        entries.forEach((entry) => delete entry.preview);

        this._loading = true;
        try {
            await app.createOrUpdateTimeEntries(entries, { applyAutoMeals: true, otherEntries: this._timeEntries });
            alert(`Es wurden ${entries.length} Schichten erfolgreich eingefügt.`, {
                type: "success",
                title: "Vorlage Eingefügt",
            });
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;

        this._selectRosterTemplate(null);

        this.synchronize(true);
    }

    private _getTimeEntriesForTemplate(template: RosterTemplate, { from, to }: DateRange) {
        const entries: TimeEntry[] = [];

        let date = from;

        while (date < to) {
            const weekDay = new Date(date).getDay();
            const shifts = template.shifts.filter((t) => t.day === weekDay);

            for (let { employee: employeeId, position: positionId, start, end, break: breakPlanned } of shifts) {
                const { position } = app.getPosition(positionId) || { position: null };
                const employee = employeeId ? app.getEmployee(employeeId) : null;
                const contract = employee?.getContractForDate(date);
                const absence = this._absences.find(
                    (a) =>
                        a.employeeId === employeeId &&
                        a.start <= date &&
                        a.end > date &&
                        (a.status === AbsenceStatus.Approved || a.status === AbsenceStatus.Inferred)
                );

                if (!position || !position.active) {
                    continue;
                }

                if (
                    !employee ||
                    !!absence ||
                    !contract ||
                    contract.blocked ||
                    !employee.positions.some((p) => p.id === position.id)
                ) {
                    employeeId = null;
                }

                const entry = new TimeEntry({
                    employeeId,
                    position,
                    positionId: position.id,
                    date,
                    preview: true,
                });

                const isPast = entry.date < toDateString(new Date());
                const [startTS, endTS] = parseTimes(date, start, end);

                if (isPast) {
                    entry.startFinal = startTS;
                    entry.endFinal = endTS;
                } else {
                    entry.startPlanned = startTS;
                    entry.endPlanned = endTS;
                    entry.breakPlanned = breakPlanned ?? null;
                }

                const { time, departments } = this._activeTab;

                if (
                    app.hasAccess({ timeEntry: entry }) &&
                    (!departments || departments.includes(entry.position!.departmentId)) &&
                    (!time || entry.isWithin(time)) &&
                    // Deduplicate entries in case template is applied twice
                    (!entry.employeeId ||
                        !this._timeEntries.some(
                            (e) =>
                                !e.deleted &&
                                e.employeeId === entry.employeeId &&
                                e.positionId === entry.positionId &&
                                e.start.getTime() === entry.start.getTime() &&
                                e.end.getTime() === entry.end.getTime()
                        ))
                ) {
                    entries.push(entry);
                }
            }
            date = dateAdd(date, { days: 1 });
        }

        return entries;
    }

    private async _clearTimeEntries(e: Event) {
        this._clearRosterDialog.hide();
        e.preventDefault();

        const selected = new FormData(e.target as HTMLFormElement);

        let { time, departments, types } = this._activeTab;

        if (!departments) {
            departments = this._filteredDepartments.map((d) => d.id);
        }

        const entries = this._timeEntries.filter(
            (e) =>
                app.hasAccess({ timeEntry: e }) &&
                !e.deleted &&
                e.date >= this.dateRange!.from &&
                e.date < this.dateRange!.to &&
                [TimeEntryType.Work, TimeEntryType.CompDay, TimeEntryType.Free].includes(e.type) &&
                (!types || [TimeEntryType.Work, ...types].includes(e.type)) &&
                (!e.position || !departments || departments.includes(e.position.departmentId)) &&
                (!time || e.isWithin(time)) &&
                (selected.has("planned") || e.type !== TimeEntryType.Work || e.startFinal) &&
                (selected.has("finished") || e.type !== TimeEntryType.Work || !e.startFinal)
        );

        const confirmed = await confirm(
            `Sind sie sicher dass Sie diese ${entries.length} Einträge löschen ` +
                `möchten? Diese Aktion kann nicht rückgängig gemacht werden!`,
            `${entries.length} Einträge Löschen`,
            "Abbrechen",
            { title: "Dienstplan Leeren", type: "destructive" }
        );
        if (!confirmed) {
            return;
        }

        app.removeTimeEntries(entries);
        this.synchronize(true);
    }

    private _getTemplateDepartments(t: RosterTemplate) {
        const departments = new Map<number, { name: string; color: string; count: number; id: number }>();
        for (const shift of t.shifts) {
            const r = app.getPosition(shift.position);
            if (!r) {
                continue;
            }

            if (!departments.has(r.department.id)) {
                departments.set(r.department.id, {
                    id: r.department.id,
                    name: r.department.name,
                    color: r.department.color,
                    count: 0,
                });
            }

            departments.get(r.department.id)!.count++;
        }
        return [...departments.values()];
    }

    private async _goToIssue(issue: Issue) {
        this._issuePopover.hide();
        for (const entry of issue.timeEntries) {
            if (!entry.position) {
                continue;
            }

            const { department } = app.getDepartment(entry.position.departmentId);

            if (!department) {
                continue;
            }

            this._setActive({
                date: entry.date,
                employee: entry.employeeId,
                department: department.id,
                entry: entry.id,
            });
        }
    }

    private _updateRosterOrder = debounce((id: number, rosterOrder: string[]) => {
        app.updateDepartment({ id, rosterOrder });
    }, 3000);

    private _moveEmployee(dep: DepartmentData, i: number, direction: "up" | "down") {
        const employees = dep.employees;
        const employee = employees[i];
        employees.splice(i, 1);
        employees.splice(direction === "up" ? i - 1 : i + 1, 0, employee);
        const rosterOrder = employees.map((e) => e.employeeData.employee.id.toString());
        this.requestUpdate();
        dep.department.rosterOrder = rosterOrder;
        this._updateRosterOrder(dep.department.id, rosterOrder);
    }

    private _setActiveTimeout: any;
    private _setActive(
        {
            date = this._activeDate,
            employee = this._activeEmployee,
            department = this._activeDepartment,
            entry = this._activeEntry,
            field = this._activeField,
        }: {
            date?: DateString | null;
            employee?: number | null;
            department?: number | null;
            entry?: string | null;
            field?: string | null;
        } = {},
        instant = false
    ) {
        if (date && employee && department && !entry) {
            const first = this._timeEntries.find(
                (e) =>
                    e.employeeId === employee &&
                    e.date === date &&
                    (!e.position || e.position.departmentId === department) &&
                    !e.deleted &&
                    (!this._activeTab.time || e.isWithin(this._activeTab.time))
            );
            entry = (first && first.id) || "new";
        }

        const doIt = async () => {
            this._activeDate = date;
            this._activeEmployee = employee;
            this._activeDepartment = department || this._activeDepartment;
            this._activeEntry = entry;
            this._activeField = field;
            await this.updateComplete;
            const day = this.renderRoot!.querySelector(".employee-day.active");
            if (day) {
                try {
                    // @ts-ignore
                    day.scrollIntoViewIfNeeded();
                } catch (e) {
                    day.scrollIntoView({ block: "center" });
                }
            }
        };

        clearTimeout(this._setActiveTimeout);

        if (entry || instant) {
            doIt();
        } else {
            this._setActiveTimeout = setTimeout(doIt, 500);
        }
    }

    private _dayInput() {
        const entryEls =
            (this._activeEntry &&
                (this.renderRoot!.querySelectorAll(
                    `ptc-roster-entry[id^="entry-${this._activeEntry}"]`
                ) as any as RosterEntry[])) ||
            [];
        for (const el of entryEls) {
            el.requestUpdate();
        }
    }

    private async _editAbsence(absence: Absence) {
        const edited = await this._absenceDialog.show(absence);
        if (edited) {
            return this.synchronize(true);
        }
    }

    private async _newAvailability(employeeId: number, date: DateString) {
        const created = await this._availabilityDialog.show(
            new Availability({
                employeeId,
                status: AvailabilityStatus.Available,
                date,
            })
        );
        if (created) {
            this._availabilities = await app.api.getAvailabilities(
                new GetAvailabilitesParams({
                    ...this.dateRange,
                    employees: app.employees.map((e) => e.id),
                })
            );
            this.refresh();
        }
    }

    private async _editAvailability(av: Availability) {
        const edited = await this._availabilityDialog.show(av);
        if (edited) {
            this._availabilities = await app.api.getAvailabilities(
                new GetAvailabilitesParams({
                    ...this.dateRange,
                    employees: app.employees.map((e) => e.id),
                })
            );
            this.refresh();
        }
    }

    private async _addRosterNote(date: DateString) {
        const note = new RosterNote({
            venueId: this._venue!.id,
            start: date,
            end: dateAdd(date, { days: 1 }),
            text: "",
            departments: this._activeTab.departments?.map((d) => d.toString()),
        });
        this._rosterNotes.push(note);
        this.refresh();
        await this.updateComplete;
        setTimeout(() => this._editRosterNote(note), 100);
    }

    private _editRosterNote(note: RosterNote) {
        this._rosterNotePopover.hide();
        if (!app.hasPermission(`manage.roster.notes`)) {
            return;
        }
        const noteEl = this.renderRoot.querySelector(`[data-roster-note="${note.id || "new"}"]`) as HTMLElement;
        const change = () => this._updateRosterNoteLanes();
        const done = (e: CustomEvent<{ deleted?: boolean }>) => {
            this._rosterNotePopover.removeEventListener("change", change);
            this._rosterNotePopover.removeEventListener("done", done);
            if (e.detail?.deleted) {
                const index = this._rosterNotes.indexOf(note);
                this._rosterNotes.splice(index, 1);
                this._updateRosterNoteLanes();
            }
        };
        this._rosterNotePopover.addEventListener("change", change);
        this._rosterNotePopover.addEventListener("done", done);
        this._rosterNotePopover.rosterNote = note;
        this._rosterNotePopover.requestUpdate("rosterNote");
        this._rosterNotePopover.showAt(noteEl, true);
    }

    private _getRosterNoteStyle(note: RosterNote) {
        if (!this.dateRange) {
            return "";
        }

        const rosterHeader = this.renderRoot.querySelector(".roster-header") as HTMLElement;
        if (!rosterHeader) {
            return "";
        }
        const dayHeaders = [...this.renderRoot.querySelectorAll(".day-header")] as HTMLElement[];
        const startDay = dayHeaders.find((h) => h.dataset.date === note.start) || dayHeaders[0];
        const endDay = dayHeaders.find((h) => h.dataset.date === note.end);
        const right = endDay ? rosterHeader.offsetWidth - endDay.offsetLeft : 0;
        let styles = `left: ${startDay.offsetLeft}px; right: ${right}px; --color-highlight: ${note.color};`;
        if (note.start < this.dateRange.from) {
            styles += "border-top-left-radius: 0; border-bottom-left-radius: 0; border-left: none;";
        }
        if (note.end > this.dateRange.to) {
            styles += "border-top-right-radius: 0; border-bottom-right-radius: 0; border-right: none;";
        }
        return styles;
    }

    private _updateRosterNoteStyles() {
        const els = this.renderRoot.querySelectorAll(".roster-note") as NodeListOf<HTMLElement>;
        for (const el of els) {
            const note = this._rosterNotes.find((note) =>
                el.dataset.rosterNote === "new" ? !note.id : el.dataset.rosterNote === note.id?.toString()
            );
            if (note) {
                el.setAttribute("style", this._getRosterNoteStyle(note));
            }
        }
    }

    private _updateDepartmentHeaderStyles() {
        const main = this.renderRoot.querySelector(".main") as HTMLDivElement;
        const headers = this.renderRoot.querySelectorAll(".department-header");
        const rosterNotes = this.renderRoot.querySelector(".roster-notes") as HTMLDivElement;

        if (!main || !rosterNotes) {
            return;
        }

        for (const header of headers) {
            header.setAttribute(
                "style",
                `width: ${main.offsetWidth - 24}px; top: ${
                    rosterNotes.offsetTop + rosterNotes.offsetHeight + 8
                }px; left: 8px;`
            );
        }
    }

    private async _showAutoAssign() {
        if (!this.dateRange) {
            return;
        }
        this._collapsed.clear();
        this.classList.add("auto-assigning");
        this._displayAutoAssignMenu = true;
        await this.updateComplete;
        this._autoAssignMenu.init({
            entries: this._timeEntries,
            absences: this._absences,
            availabilities: this._availabilities,
            employees: this._employees,
            weights: {
                assignAll: 5,
                fillQuota: 5,
                avoidOvertime: 5,
                reduceAccumulatedOvertime: 5,
                minimizeCosts: 5,
                considerAvailabilities: 5,
                avoidProblems: 5,
            },
            reassignEntries: false,
            company: app.company!,
            range: this.dateRange,
            departments: this._activeTab.departments || this._venue!.departments.map((d) => d.id),
        });
    }

    private async _closeAutoAssign() {
        this.classList.remove("auto-assigning");
        this._displayAutoAssignMenu = false;
        this.refresh();
    }

    private async _undoChanges({ detail: { timeEntries } }: CustomEvent<{ timeEntries: TimeEntry[] }>) {
        if (
            !(await confirm(
                "Möchten Sie die gewählten Änderungen rückgängig machen und den Status der letzten Veröffentlichung wiederherstellen?",
                `${timeEntries.length} Änderungen Verwerfen`,
                "Abbrechen",
                { title: "Änderungen Verwerfen", icon: "rotate-left", optionsLayout: "horizontal" }
            ))
        ) {
            return;
        }

        const unpublished = timeEntries.filter((e) => !e.published);
        const published = timeEntries.filter((e) => !!e.published);

        for (const entry of published) {
            entry.date = entry.published!.date;
            entry.employeeId = entry.published!.employeeId;
            entry.startPlanned = entry.published!.startPlanned;
            entry.endPlanned = entry.published!.endPlanned;
            entry.comment = entry.published!.comment || "";
            entry.deleted = entry.published!.deleted;
            entry.positionId = entry.published!.positionId;
            entry.position = (entry.positionId && app.getPosition(entry.positionId)?.position) || null;
        }

        app.createOrUpdateTimeEntries(published);
        app.removeTimeEntries(unpublished);
        this.synchronize(true);
    }

    static styles = [
        shared,
        Checkbox.styles,
        RosterCosts.styles,
        RosterTargetsElement.styles,
        Balance.styles,
        css`
            :host {
                display: block;
                position: relative;
            }

            :host(.auto-assigning) .main {
                cursor: not-allowed;
            }

            :host(:not(.auto-assigning)) ptc-roster-entry {
                transition: none !important;
                transform: none !important;
                opacity: 1 !important;
            }

            :host(.auto-assigning) .main > * {
                pointer-events: none;
            }

            .main {
                overflow: auto;
                scroll-behavior: smooth;
            }

            .main-inner {
                position: relative;
            }

            .row {
                display: flex;
                flex-direction: row;
                min-width: fit-content;
            }

            .row > * {
                min-width: 8.1em;
                width: 0;
                box-sizing: border-box;
                flex: 1;
            }

            .main-outer.minimal .row > * {
                min-width: 2.5em;
            }

            .row > :first-child {
                width: 200px;
                flex: none;
            }

            .row:not(:last-child) {
                border-bottom: solid 1px var(--shade-1);
            }

            .row > :not(:last-child) {
                border-right: solid 1px var(--shade-1);
            }

            .row > .today {
                border-left: solid 1px var(--blue-bg);
            }

            .roster-header {
                position: sticky;
                top: 0;
                background: rgba(255, 255, 255, 0.95);
                z-index: 9;
                margin-bottom: 0.5em;
                border-bottom: solid 1px var(--shade-1);
                min-width: fit-content;
            }

            .filter-wrapper {
                position: sticky;
                left: 0;
                background: rgba(255, 255, 255, 0.95);
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 5;
            }

            .filter-wrapper i.search {
                color: var(--shade-5);
            }

            .filter-wrapper:focus-within i.search {
                color: var(--color-primary);
            }

            .day-header {
                font-weight: bold;
                text-align: center;
                padding: 0.5em;
                position: relative;
            }

            .day-subheader {
                font-size: 80%;
                opacity: 0.7;
                margin-top: 2px;
            }

            .day-header.holiday {
                color: var(--violet);
            }

            .day-header.sunday {
                color: var(--orange);
            }

            .day-header.today {
                color: var(--blue);
            }

            .employee-day::before {
                content: "";
                display: block;
                position: absolute;
                inset: 0;
                background-clip: content-box;
                opacity: 0.05;
            }

            .employee-day.sunday::before {
                background: var(--orange);
            }

            .employee-day.holiday::before {
                background: var(--violet);
            }

            .employee-day.today::before {
                background: var(--blue);
            }

            .department {
                margin-bottom: 0.5em;
                min-width: fit-content;
            }

            .department-header {
                letter-spacing: 5px;
                background: transparent;
                border-bottom: solid 2px;
                margin-bottom: 0.5em;
                color: var(--color-highlight);
                grid-column: span 8;
                text-align: center;
                font-weight: bold;
                font-size: 80%;
                cursor: pointer;
                position: relative;
                padding: 4px 0 2px 0;
                box-sizing: border-box;
                border-radius: 5px;
                border: solid 2px var(--color-highlight);
                background: rgba(255, 255, 255, 0.95);
                position: sticky;
                left: 16px;
                z-index: 8;
            }

            .department-body {
                display: contents;
            }

            .department-header:hover::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(255, 255, 255, 0.3);
            }

            .department.collapsed > .department-body {
                display: none;
            }

            .department.collapsed > .department-header {
                background: var(--color-highlight);
                color: #fff;
            }

            .employee-row:not(:last-child) {
                border-bottom: solid 1px var(--shade-1);
            }

            .employee-day {
                display: flex;
                flex-direction: column;
                position: relative;
                cursor: pointer;
                max-height: 20em;
                overflow: auto;
            }

            .employee-day > * {
                margin: 3px;
            }

            .employee-day > :not(:last-child):not(.add-button) {
                margin-bottom: 0;
            }

            .employee-day.dragover {
                background: var(--color-primary-bg);
            }

            .employee-day.blocked {
                background: var(--shade-1);
            }

            .employee-day.active {
                background: var(--shade-1);
            }

            .employee-day .blocked-reason {
                text-align: center;
                opacity: 0.5;
            }

            .employee-header {
                cursor: pointer;
                position: relative;
                position: sticky;
                left: 0;
                z-index: 5;
                background: rgba(255, 255, 255, 0.95);
            }

            .employee-header:hover .employee-name {
                color: var(--color-primary);
            }

            .employee-header .stretch {
                width: 0;
            }

            .employee-header ptc-avatar {
                margin: 0.4em;
                font-size: 0.95em;
            }

            .employee-move-buttons {
                display: flex;
                flex-direction: column;
                position: absolute;
                right: 0.3em;
                top: 0.3em;
            }

            .employee-header:not(:hover) .employee-move-buttons {
                display: none;
            }

            .employee-move-buttons button {
                padding: 0 0.1em;
                background: rgba(255, 255, 255, 0.9);
            }

            .employee-move-buttons button:first-child {
                margin-bottom: 0.1em;
            }

            .employee-name {
                font-weight: 600;
                line-height: 1.2em;
                margin-bottom: 0.3em;
            }

            .add-button {
                font-size: var(--font-size-tiny);
                height: 100%;
                padding: 0.3em;
                background: rgba(255, 255, 255, 0.7) !important;
            }

            .add-button:not(:first-child) {
                padding: 0.3em;
                margin-top: 3px;
            }

            .employee-day:not(:hover) .add-button:not(:focus):not(.selected) {
                display: none;
            }

            ptc-roster-entry {
                cursor: pointer !important;
            }

            .selected,
            .box.inverted {
                box-shadow: var(--color-primary) 0 0 0 0.2em !important;
            }

            [draggable="true"] {
                cursor: grab;
                opacity: 0.999;
            }

            [draggable="true"]:active {
                cursor: grabbing;
            }

            ptc-roster-entry.dragging {
                opacity: 0.5;
            }

            ptc-roster-entry,
            .shift-template-wrapper {
                transition: all 0.1s;
            }

            ptc-roster-entry[draggable="true"]:hover,
            .shift-template-wrapper:hover {
                box-shadow: var(--color-highlight, var(--shade-4)) 0 0 0 0.2em;
            }

            :host(.dragging) {
                cursor: grabbing;
            }

            :host(.dragging) .employee-day *:not(.dragging) {
                pointer-events: none;
            }

            .row.costs {
                position: sticky;
                bottom: 0;
                z-index: 9;
                border-top: solid 1px var(--shade-2);
                background: rgba(255, 255, 255, 0.95);
            }

            .absence {
                background: var(--color-highlight);
                color: var(--color-bg);
                --gap: 2px;
                margin: var(--gap) 0;
            }

            .absence-start {
                border-top-left-radius: 0.5em;
                border-bottom-left-radius: 0.5em;
                margin-left: var(--gap);
            }

            .absence-end {
                border-top-right-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
                margin-right: var(--gap);
            }

            .availabilities {
                margin: 0;
            }

            .availability::before {
                content: "";
                ${mixins.fullbleed()};
                background: var(--color-highlight);
                opacity: 0.2;
            }

            .unassigned-row {
                position: sticky;
                bottom: 0;
                z-index: 8;
                border-top: solid 1px var(--shade-1);
                border-bottom: solid 1px var(--shade-1);
                color: var(--color-highlight);
                background: var(--color-bg);
                transition: height 0.3s;
            }

            /* .unassigned-row .employee-day {
                overflow: auto;
                --scrollbar-width: 0;
            } */

            .show-charts .department-targets {
                bottom: 4.3em;
            }

            .show-charts:not(.show-targets) .unassigned-row {
                bottom: 4.3em;
            }

            .show-charts.show-targets .unassigned-row {
                bottom: 7em;
            }

            .show-targets:not(.show-charts) .unassigned-row {
                bottom: 2.8em;
            }

            .main-outer.compact .employee-header ptc-avatar,
            .main-outer.minimal .employee-header ptc-avatar {
                font-size: 0.6em;
            }

            .main-outer.compact .employee-header ptc-progress,
            .main-outer.compact .employee-header .employee-status,
            .main-outer.compact .employee-header .employee-move-buttons,
            .main-outer.minimal .employee-header ptc-progress,
            .main-outer.minimal .employee-header .employee-status,
            .main-outer.minimal .employee-header .employee-move-buttons {
                display: none;
            }

            .main-outer.compact .employee-header .employee-name,
            .main-outer.minimal .employee-header .employee-name {
                margin-top: 0.3em;
            }

            .main-outer.compact .absence i,
            .main-outer.minimal .absence i {
                font-size: var(--font-size-medium);
            }

            .add-roster-note {
                position: absolute;
                margin: auto;
                background: var(--color-bg) !important;
                bottom: 0.2em;
                left: 0.2em;
                width: calc(100% - 0.4em);
                display: block;
                opacity: 1 !important;
                padding-top: 0.2em !important;
                padding-bottom: 0.2em !important;
            }

            .day-header:not(:hover) .add-roster-note {
                display: none;
            }

            .roster-note-lane {
                height: 2em;
                position: relative;
            }

            .roster-note {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }

            .roster-note-inner {
                color: var(--color-highlight, var(--color-primary));
                position: absolute;
                left: 0.1em;
                right: 0.1em;
                bottom: 0.1em;
                top: 0.1em;
                border-radius: 0.5em;
                border: solid 1px;
                text-align: center;
                padding: 0 0.3em;
                font-size: var(--font-size-small);
                line-height: 1.9em;
            }

            .row > .balance-before,
            .row > .balance-after {
                min-width: 5em;
                width: 5em;
                flex: none;
                font-size: var(--font-size-smaller);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.1em;
                font-weight: 600;
                background: rgba(255, 255, 255, 0.95);
                position: sticky;
                z-index: 1;
            }

            .balance-before {
                left: 200px;
            }

            .balance-after {
                right: 0;
                border-left: solid 1px var(--shade-1);
            }

            .shift-template {
                padding: 0.5em 0.5em 0.5em 0.7em;
                background: var(--color-bg);
                letter-spacing: 0.05em;
            }

            .shift-template-wrapper {
                border-radius: 0.5em;
            }

            .shift-template.favorite .star {
                color: var(--yellow);
                font-weight: bold;
            }

            .shift-template .star {
                cursor: pointer;
            }

            .shift-template:not(.favorite) .star:not(:hover),
            .shift-template.favorite .star:hover {
                opacity: 0.5;
            }

            .availability-icon {
                background: var(--color-highlight);
                color: var(--color-bg);
            }

            .availability-icon.cutoff-topleft {
                clip-path: polygon(100% 0, 100% 100%, 0 100%);
            }

            .availability-icon.cutoff-bottomright {
                clip-path: polygon(100% 0, 0 0, 0 100%);
            }

            @media print {
                .main-outer,
                .main,
                .main-inner {
                    position: static !important;
                    display: block !important;
                    width: auto !important;
                    height: auto !important;
                    max-width: none;
                }

                .main-outer > * {
                    height: auto !important;
                }

                .department-header {
                    width: 100% !important;
                }

                .employee-row {
                    page-break-inside: avoid;
                }
            }
        `,
    ];

    private _renderTimeEntry(
        entry: TimeEntry,
        dep: Department,
        date: DateString,
        blocked?: boolean,
        stackSize?: number
    ) {
        const department = (entry.position && entry.position.departmentId) || dep.id;
        const otherDep = !!entry.position && entry.position.departmentId !== dep.id;
        const allowDrag = !otherDep && !blocked;
        const issues = this._issues.filter((issue) => issue.timeEntries.some((e) => e.id === entry.id));
        const isActive = (this._activeEntry === entry.id && this._activeDepartment === dep.id) || entry.preview;
        return html`
            <ptc-roster-entry
                id="entry-${entry.id}-${dep.id}"
                draggable="${allowDrag ? "true" : "false"}"
                .entry=${entry}
                .error=${!!issues.length}
                .department=${dep}
                .venue=${this._venue!}
                .stackSize=${stackSize}
                .condensed=${app.settings.rosterDisplayMode === "compact"}
                class="tiny ${isActive ? "selected" : ""}"
                @remove=${() => this._removeTimeEntry(entry)}
                @dragstart=${(e: DragEvent) => this._dragstart(e, { entry })}
                @select=${({ detail: { field } }: CustomEvent<{ field: string }>) =>
                    this._setActive({ date, employee: entry.employeeId, department, entry: entry.id, field })}
                style="margin-top: ${Math.min(stackSize || 1, 3) + 2}px;"
            ></ptc-roster-entry>
        `;
    }

    private _renderUnassigned(dep: DepartmentData) {
        if (!app.settings.rosterDisplayUnassigned || app.settings.rosterDisplayMode === "minimal") {
            return;
        }
        return html`
            <div class="unassigned-row">
                <div class="row employee-row">
                    <div class="half-padded employee-header horizontal start-aligning layout">
                        <i class="huge user-slash"></i>
                        <div class="stretch horizontally-margined">
                            <div class="bold">${dep.department.name}</div>
                            <div>Nicht Zugewiesen</div>
                        </div>
                    </div>
                    <div class="balance-before" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                        <div><i class="plus-minus"></i></div>
                    </div>
                    ${dep.unassigned.map(({ date, entries }) => {
                        const stacks = new Map<string, TimeEntry[]>();
                        for (const entry of entries) {
                            const key = `${entry.position?.id}_${entry.startPlanned}_${entry.endPlanned}_${entry.isPublished}`;
                            if (!stacks.has(key)) {
                                stacks.set(key, []);
                            }
                            stacks.get(key)?.push(entry);
                        }

                        return html`
                            <div
                                class=${classMap({
                                    "employee-day": true,
                                    today: date === toDateString(new Date()),
                                    active:
                                        date === this._activeDate &&
                                        null === this._activeEmployee &&
                                        dep.department.id === this._activeDepartment,
                                    sunday: parseDateString(date)?.getDay() === 0,
                                    holiday: this._data.holidays.has(date),
                                })}
                                @click=${() =>
                                    this._setActive({
                                        employee: null,
                                        department: dep.department.id,
                                        date,
                                        entry: null,
                                    })}
                                @dragenter=${this._dragenter}
                                @dragleave=${this._dragleave}
                                @dragover=${this._dragover}
                                @drop=${(e: DragEvent) => this.dropIntoDay(e, null, dep.department, date)}
                            >
                                ${[...stacks.values()].map((entries) =>
                                    this._renderTimeEntry(entries[0], dep.department, date, false, entries.length)
                                )}
                                <button
                                    ?hidden=${!!entries.length}
                                    class="transparent add-button ${this._activeDepartment === dep.department.id &&
                                    this._activeDate === date &&
                                    this._activeEmployee === null &&
                                    this._activeEntry === "new"
                                        ? "selected"
                                        : ""}"
                                    @click=${(e: Event) => {
                                        e.stopPropagation();
                                        this._setActive({
                                            employee: null,
                                            department: dep.department.id,
                                            date,
                                            entry: "new",
                                        });
                                    }}
                                >
                                    <i class="plus"></i>
                                </button>
                            </div>
                        `;
                    })}
                    <div class="balance-after" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                        <div><i class="plus-minus"></i></div>
                    </div>
                </div>
            </div>
        `;
    }

    private _renderDepartment(dep: DepartmentData) {
        if (
            !this._activeTab ||
            (this._activeTab.departments && !this._activeTab.departments.includes(dep.department.id))
        ) {
            return;
        }
        const employees = dep.employees.filter(
            ({
                employeeData: {
                    employee: { name, staffNumber },
                },
            }) => `${name} ${staffNumber}`.toLowerCase().includes(this._filterString.toLowerCase())
        );
        const collapsed = !employees.length || (!this._filterString && this._collapsed.has(dep.department.id));
        const dragPositionId = this._dragData?.entry?.positionId || this._dragData?.shiftTemplate?.positionId;
        const dragPosition = dragPositionId && app.getPosition(dragPositionId)?.position;
        return html`
            <div
                class="department ${collapsed ? "collapsed" : ""}"
                style="--color-highlight: ${colors[dep.department.color] || dep.department.color}"
                ?disabled=${dragPosition && dragPosition.departmentId !== dep.department.id}
                data-department=${dep.department.id}
            >
                <div class="department-header" @click=${() => this._toggleDepartment(dep.department)}>
                    ${dep.department.name.toUpperCase()}
                    <i class="angle-${collapsed ? "right" : "down"}"></i>
                </div>

                <!-- spacer row to make sure parent element retains it's width when collapsed -->
                <div class="row" style="height: 0" ?hidden=${!collapsed}>
                    <div></div>
                    <div class="balance-before" ?hidden=${!app.settings.rosterDisplayTimeBalances}></div>
                    ${this._data.dates.map(() => html`<div class="employee-day"></div>`)}
                    <div class="balance-after" ?hidden=${!app.settings.rosterDisplayTimeBalances}></div>
                </div>

                <div class="department-body ${this._previewRosterTemplate ? "non-interactive" : ""}">
                    ${employees.map((emp, i) => {
                        const maxEntries = Math.max(...emp.days.map((d) => d.entries.length), 1);
                        const entryHeight = app.settings.rosterDisplayMode === "regular" ? 3.2 : 2.1;
                        const rowHeight = entryHeight * maxEntries + 0.2;
                        return html`
                            <ptc-roster-row
                                .employee=${emp.employeeData}
                                .days=${emp.days}
                                .department=${dep}
                                .venue=${this._venue!}
                                .activeDate=${this._activeDate}
                                .activeDepartment=${this._activeDepartment}
                                .activeEmployee=${this._activeEmployee}
                                .activeEntry=${this._activeEntry}
                                .activeTab=${this._activeTab}
                                .issues=${this._issues}
                                .canMoveUp=${i > 0}
                                .canMoveDown=${i < dep.employees.length - 1}
                                @move-up=${() => this._moveEmployee(dep, i, "up")}
                                @move-down=${() => this._moveEmployee(dep, i, "down")}
                                @header-clicked=${() => this.go(`employees/${emp.employeeData.employee.id}/time`)}
                                @select=${({
                                    detail: { date, entry, department, field, instant },
                                }: CustomEvent<{
                                    date?: DateString;
                                    entry?: string | null;
                                    department: number | null;
                                    field?: string | null;
                                    instant?: boolean;
                                }>) =>
                                    this._setActive(
                                        {
                                            date,
                                            department,
                                            entry,
                                            field,
                                            employee: emp.employeeData.employee.id,
                                        },
                                        instant
                                    )}
                                @begindrag=${(e: CustomEvent<{ data: DragData }>) => (this._dragData = e.detail.data)}
                                @remove=${({ detail: { entry } }: CustomEvent<{ entry: TimeEntry }>) =>
                                    this._removeTimeEntry(entry)}
                                @drop-into-day=${({
                                    detail: { dragEvent, department, employee, date },
                                }: CustomEvent<{
                                    dragEvent: DragEvent;
                                    department: Department;
                                    employee: Employee;
                                    date: DateString;
                                }>) => this.dropIntoDay(dragEvent, employee, department, date)}
                                @edit-absence=${(e: CustomEvent<{ absence: Absence }>) =>
                                    this._editAbsence(e.detail.absence)}
                                class="row employee-row"
                                ?disabled=${dragPosition &&
                                !emp.employeeData.employee.positions.some((p) => p.id === dragPosition!.id)}
                                style="height: ${rowHeight}em"
                            ></ptc-roster-row>
                        `;
                    })}
                    ${this._renderUnassigned(dep)} ${this._renderTargets(dep.department)}
                </div>
            </div>
        `;
    }

    private _renderTargets(department: Department) {
        if (
            !app.settings.rosterDisplayTargets ||
            app.settings.rosterDisplayMode === "minimal" ||
            !this.dateRange ||
            this._previewRosterTemplate
        ) {
            return;
        }

        const { from, to } = this.dateRange;

        return html`
            <ptc-roster-targets
                .targets=${this._targets}
                .entries=${this._timeEntries}
                .department=${department}
                .editable=${app.hasPermission(`manage.planning.roster`)}
                .from=${from}
                .to=${to}
                class="noprint"
            ></ptc-roster-targets>
        `;
    }

    private _renderCosts() {
        const range = this.dateRange;
        if (
            !range ||
            !this._venue ||
            !app.hasPermission(`manage.roster.costs`) ||
            !app.settings.rosterDisplayCosts ||
            this._previewRosterTemplate
        ) {
            return;
        }
        return html`
            <ptc-roster-costs
                .venue=${this._venue}
                .entries=${this._timeEntries}
                .from=${range.from}
                .to=${range.to}
                class="noprint"
                .departments=${this._filteredDepartments}
            ></ptc-roster-costs>
        `;
    }

    private _renderDayMenu() {
        if (this._previewRosterTemplate) {
            return;
        }
        return html`
            <div class="relative noprint" style="width: 18em; border-left: solid 1px var(--shade-1);">
                <ptc-employee-day
                    class="fullbleed"
                    .entries=${[...this._timeEntries, ...this._previousTimeEntries]}
                    .absences=${this._absences}
                    .availabilities=${this._availabilities}
                    .activeDate=${this._activeDate!}
                    .activeEmployee=${this._activeEmployee!}
                    .activeDepartment=${this._activeDepartment!}
                    .activeEntry=${this._activeEntry}
                    .activeField=${this._activeField}
                    .issues=${this._issues}
                    .timeFilter=${this._activeTab.time}
                    .active=${!!this.active && !!this._activeDate && !!this._activeDepartment}
                    @select=${({
                        detail: { date, entry, department, field, instant },
                    }: CustomEvent<{
                        date?: DateString;
                        entry?: string | null;
                        department: number | null;
                        field?: string | null;
                        instant?: boolean;
                    }>) =>
                        this._setActive(
                            {
                                date,
                                department,
                                entry,
                                field,
                            },
                            instant
                        )}
                    @addentry=${(e: CustomEvent<Partial<TimeEntry>>) => this._addTimeEntry(e.detail, true)}
                    @updateentry=${(e: CustomEvent<TimeEntry>) => this._updateTimeEntry(e.detail)}
                    @input=${() => this._dayInput()}
                    @close=${() => this._setActive({ date: null, department: null, entry: null, field: null }, true)}
                    @remove=${(e: CustomEvent<{ entry: TimeEntry }>) => this._removeTimeEntry(e.detail.entry)}
                    @begindrag=${(e: CustomEvent<{ data: DragData }>) => (this._dragData = e.detail.data)}
                    @edit-availability=${(e: CustomEvent<{ availability: Availability }>) =>
                        this._editAvailability(e.detail.availability)}
                    @new-availability=${() => this._newAvailability(this._activeEmployee!, this._activeDate!)}
                ></ptc-employee-day>
            </div>
        `;
    }

    private _renderAutoAssignMenu() {
        if (this._previewRosterTemplate) {
            return;
        }
        return html` <div class="relative noprint" style="width: 18em; border-left: solid 1px var(--shade-1);">
            <ptc-auto-assign-menu
                class="fullbleed"
                @close=${this._closeAutoAssign}
                @updated=${() => this.refresh()}
            ></ptc-auto-assign-menu>
        </div>`;
    }

    private _renderDayHeader() {
        const today = toDateString(new Date());
        return html`
            <div class="roster-header">
                <div class="row">
                    <div class="filter-wrapper half-spacing center-aligning horizontal layout">
                        <i class="left-margined search"></i>
                        <input
                            class="plain stretch"
                            id="filterInput"
                            type="text"
                            placeholder="Suchen..."
                            @input=${this._updateFilter}
                            @keydown=${(e: Event) => e.stopPropagation()}
                        />
                    </div>

                    <div class="balance-before" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                        <div><i class="plus-minus"></i></div>
                    </div>

                    ${this._data.dates.map((date) => {
                        const holiday = this._data.holidays.get(date);
                        return html`
                            <div
                                class=${classMap({
                                    "day-header": true,
                                    holiday: !!holiday,
                                    today: date === today,
                                    sunday: new Date(date).getDay() === 0,
                                })}
                                data-date=${date}
                            >
                                ${app.settings.rosterDisplayMode === "minimal"
                                    ? html`<div class="smaller negatively-margined">
                                          <div>${formatWeekDayShort(date)}</div>
                                          <div class="smaller day-subheader">${formatDateShort(date, true)}</div>
                                      </div>`
                                    : html`
                                          ${formatWeekDay(date)}
                                          <div class="day-subheader ellipsis">
                                              ${holiday ? holiday.name : formatDate(date)}
                                          </div>
                                          <button
                                              class="skinny top-margined smaller subtle add-roster-note"
                                              ?hidden=${!app.hasPermission(`manage.roster.notes`)}
                                              @click=${() => this._addRosterNote(date)}
                                          >
                                              <i class="sticky-note"></i>
                                              Notiz
                                          </button>
                                      `}
                            </div>
                        `;
                    })}

                    <div class="balance-after" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                        <div>
                            <div><i class="plus-minus"></i></div>
                        </div>
                    </div>
                </div>

                <div class="roster-notes">
                    ${this._rosterNoteLanes.map(
                        (notes) =>
                            html` <div class="roster-note-lane" ?hidden=${!app.settings.rosterDisplayNotes}>
                                ${notes.map(
                                    (note) => html`
                                        <div class="roster-note" data-roster-note="${note.id || "new"}">
                                            <div
                                                class="roster-note-inner ellipsis click"
                                                @click=${() => this._editRosterNote(note)}
                                                title=${note.text}
                                            >
                                                ${note.text.split("\n")[0]}
                                            </div>
                                        </div>
                                    `
                                )}
                            </div>`
                    )}
                </div>
            </div>
        `;
    }

    private _renderRosterTemplatePreview() {
        if (!this._previewRosterTemplate) {
            return;
        }
        const template = this._previewRosterTemplate;

        return html`<div class="padded spacing end-aligning horizontal layout border-bottom">
            <div class="padded stretch">
                <div class="smaller blue colored-text bottom-margined">
                    <i class="floppy-disk-circle-arrow-right"></i>
                    Vorlage Anwenden
                </div>
                <div class="bottom-margined semibold ellipsis">${template.name}</div>
                <div class="smaller horizontal spacing wrapping layout">
                    ${this._filteredDepartments.map((dep) => {
                        const count = this._previewTimeEntries?.filter((e) =>
                            dep.positions.some((pos) => pos.id === e.positionId)
                        ).length;
                        if (!count) {
                            return;
                        }
                        return html`<div
                            class="box"
                            style="padding: 0.25em 0.5em; --color-highlight: ${colors[dep.color] || dep.color}"
                        >
                            ${dep.name}
                            <span class="bold">${count}</span>
                        </div>`;
                    })}
                </div>
            </div>
            <div class="smaller horizontal spacing layout">
                <button class="primary" @click=${this._applyRosterTemplate} style="width: 15em;">Anwenden</button>
                <button class="ghost" @click=${() => this._selectRosterTemplate(null)} style="width: 15em;">
                    Abbrechen
                </button>
            </div>
        </div>`;
    }

    private _renderGlobalShiftSuggestions() {
        if (!app.settings.rosterDisplaySuggestions) {
            return;
        }
        const favOnly = app.settings.rosterDisplayFavSuggestionsOnly;

        const templates = this._frequentTimes.filter(
            ({ venue, favorite }) => venue === this._venue!.id && (!favOnly || favorite)
        );
        const templatesByDepartment = this._filteredDepartments.map((department) => ({
            department,
            templates: templates.filter((t) => department.positions.some((p) => p.id === t.positionId)),
        }));

        const absences: ShiftTemplate[] = [{ type: TimeEntryType.CompDay }, { type: TimeEntryType.Free }];

        if (app.hasPermission("manage.employees.absences")) {
            absences.unshift({ type: TimeEntryType.Sick }, { type: TimeEntryType.Vacation });
        }

        return html`
            <div class="noprint vertical layout" style="width: 18em; border-left: solid 1px var(--shade-1);">
                <div class="small half-padded horizontal center-aligning half-spacing layout border-bottom">
                    <div class="padded stretch semibold"><i class="lightbulb-on"></i> Vorschläge & Favoriten</div>

                    <button
                        class="small skinny transparent ${app.settings.rosterDisplayFavSuggestionsOnly
                            ? "yellow bold"
                            : ""}"
                        title="Nur Favoriten Anzeigen"
                        @click=${() =>
                            app.updateSettings({
                                rosterDisplayFavSuggestionsOnly: !app.settings.rosterDisplayFavSuggestionsOnly,
                            })}
                    >
                        <i class="star"></i>
                    </button>

                    <button
                        class="skinny transparent text-left-aligning"
                        @click=${() => app.updateSettings({ rosterDisplaySuggestions: false })}
                        style="margin-right: 0.25em"
                    >
                        <div class="horizontal spacing layout">
                            <i class="times"></i>
                        </div>
                    </button>
                </div>

                <div class="padded spacing evenly stretching horizontal layout">
                    ${absences.map(
                        (t) => html`
                            <div
                                class="shift-template-wrapper"
                                style="--color-highlight: ${timeEntryTypeColor(t.type!)}"
                                title="${app.localized.timeEntryTypeLabel(t.type!)}"
                            >
                                <div
                                    class="shift-template text-centering padded box"
                                    draggable="true"
                                    @dragstart=${(e: DragEvent) =>
                                        this._dragstart(e, {
                                            shiftTemplate: t,
                                        })}
                                >
                                    <i class="${app.localized.timeEntryTypeIcon(t.type!)}"></i>
                                </div>
                                <ptc-popover trigger="hover" class="non-interactive tooltip" alignment="bottom">
                                    <div class="larger">${app.localized.timeEntryTypeLabel(t.type!)}</div>
                                    <div class="subtle smaller"><i class="left"></i> Per Drag & Drop Einfügen</div>
                                </ptc-popover>
                            </div>
                        `
                    )}
                </div>

                <ptc-scroller class="stretch collapse">
                    ${templatesByDepartment.map(
                        ({ department, templates }) => html`
                            <div class="vertical layout">
                                <button
                                    class="slim transparent horizontally-margined"
                                    style="--color-highlight: ${colors[department.color] || department.color}"
                                    @click=${() => this._toggleDepartmentSuggestions(department)}
                                >
                                    <div class="horizontal center-aligning layout">
                                        <div class="stretch collapse ellipsis text-left-aligning">
                                            ${department.name}
                                        </div>
                                        <i
                                            class="${this._collapsedSuggestions.has(department.id)
                                                ? "angle-right"
                                                : "angle-down"}"
                                        ></i>
                                    </div>
                                </button>
                            </div>
                            <ptc-drawer .collapsed=${this._collapsedSuggestions.has(department.id)}>
                                <div class="padded spacing vertical layout" style="padding-top: 0.2em">
                                    ${!templates.length
                                        ? html`
                                              <div class="small padded subtle box">
                                                  Es wurden keine Schichtvorschläge für diese Abteilung gefunden.
                                              </div>
                                          `
                                        : ""}
                                    ${templates.map((t) => {
                                        const position = app.getPosition(t.positionId!)?.position;
                                        return html`
                                            <div class="shift-template-wrapper">
                                                <div
                                                    class="small shift-template box start-aligning horizontal layout ${t.favorite
                                                        ? "favorite"
                                                        : ""}"
                                                    style="--color-highlight: ${position &&
                                                    app.getPositionColor(position)}; padding-bottom: 0.3em;"
                                                    draggable="true"
                                                    @dragstart=${(e: DragEvent) =>
                                                        this._dragstart(e, {
                                                            shiftTemplate: t,
                                                        })}
                                                >
                                                    <div class="stretch">
                                                        <div class="smaller ellipsis">${position?.name}</div>
                                                        <div class="larger">
                                                            ${t.start?.slice(0, 5)} - ${t.end?.slice(0, 5)}
                                                            ${typeof t.breakPlanned === "number"
                                                                ? html` <span class="smaller">
                                                                      <i class="smaller coffee"></i> ${toDurationString(
                                                                          t.breakPlanned
                                                                      )}
                                                                  </span>`
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <i
                                                        class="star"
                                                        ?hidden=${!t.start}
                                                        @click=${() => this._toggleFavorite(t)}
                                                    ></i>
                                                </div>
                                            </div>
                                        `;
                                    })}
                                </div>
                            </ptc-drawer>
                        `
                    )}
                </ptc-scroller>
            </div>
        `;
    }

    private _renderHeader() {
        if (!this._venue || !this.dateRange) {
            return;
        }

        const unpublishedCount = this._unpublishedCount;

        const positions = this._filteredDepartments.flatMap((d) => d.positions.map((p) => p.id));
        const issues = this._issues.filter(
            (issue) =>
                issue.date >= this.dateRange!.from &&
                issue.date < this.dateRange!.to &&
                issue.timeEntries?.some(
                    (e) =>
                        e.position &&
                        positions.includes(e.position.id) &&
                        (!this._activeTab.time || e.isWithin(this._activeTab.time))
                )
        );

        const isWeekRange = inferRangeType(this.dateRange) === "week";

        return html`
            <div class="padded spacing center-aligning horizontal layout border-bottom relative header">
                <ptc-roster-tabs .activeIndex=${this._activeTabIndex} class="noprint"></ptc-roster-tabs>

                <div class="horizontally-padded printonly">Dienstplan <strong>${this._venue?.name}</strong></div>

                <div class="stretch"></div>

                <ptc-date-range-picker
                    @range-selected=${(e: CustomEvent<DateRange>) =>
                        this.go(null, { ...this.router.params, ...e.detail })}
                    .range=${this.dateRange}
                    maxDays=${35}
                ></ptc-date-range-picker>

                <div class="stretch noprint"></div>

                ${unpublishedCount
                    ? html`
                          <button
                              title="Änderungen verwalten"
                              class="orange slim transparent noprint"
                              ?disabled=${!app.hasPermission(`manage.roster.publish`)}
                          >
                              <i class="pencil"></i>
                              ${unpublishedCount}
                          </button>
                          <ptc-popover class="unpadded" style="width: 35em">
                              <ptc-roster-changes
                                  .timeEntries=${this._unpublishedEntries}
                                  @publish=${this._publishChanges}
                                  @undo=${this._undoChanges}
                              ></ptc-roster-changes>
                          </ptc-popover>
                      `
                    : html`
                          <button class="green slim transparent noprint" disabled>
                              <div class="horizontal layout">
                                  <i class="check"></i>
                              </div>
                          </button>
                      `}

                <button
                    title="Probleme anzeigen"
                    class="${issues.length ? "red" : ""} transparent slim noprint"
                    ?disabled=${!issues.length}
                >
                    <i class="exclamation-triangle"></i>
                    ${issues.length}
                </button>

                <ptc-popover class="unpadded" id="issuePopover">
                    <div class="red colored-text semibold padded border-bottom">
                        <div class="half-padded"><i class="exclamation-triangle"></i> ${issues.length} Probleme</div>
                    </div>
                    <ptc-scroller style="max-height: 80vh">
                        ${issues.map(
                            (issue) => html`
                                <ptc-issue
                                    class="small border-bottom click"
                                    .issue=${issue}
                                    @click=${() => this._goToIssue(issue)}
                                ></ptc-issue>
                            `
                        )}
                    </ptc-scroller>
                </ptc-popover>

                <button title="Dienstplan teilen" class="slim transparent noprint"><i class="share"></i></button>

                <ptc-popover class="unpadded" style="width: 28em;">
                    <div class="padded border-bottom semibold center-aligning spacing horizontal layout">
                        <div class="stretch half-padded"><i class="share"></i> Dienstplan Teilen</div>
                    </div>

                    <div class="padded">
                        ${until(
                            this._publicUrlWithFilters.then(
                                (url) => html`
                                    ${unpublishedCount
                                        ? html`
                                              <div class="small padded margined orange box">
                                                  <i class="exclamation-triangle"></i><strong>Achtung:</strong> Es
                                                  liegen <strong>${unpublishedCount}</strong> nicht veröffentlichte
                                                  Änderungen vor! Unveröffentlichte Änderungen erscheinen nicht im
                                                  geteilten Dienstplan!
                                              </div>
                                          `
                                        : ""}

                                    <div class="horizontally-margined padded">
                                        <div class="tiny semibold faded bottom-margined">
                                            <i class="link"></i> Teilbarer Link:
                                        </div>
                                        <div class="small blue colored-text" style="word-break: break-all;">
                                            <a href="${url}" target="_blank">${url}</a>
                                        </div>
                                    </div>

                                    <div
                                        class="small top-margined horizontally-margined horizontal evenly stretching layout"
                                    >
                                        <button class="transparent" type="button" @click=${this._print}>
                                            <i class="print"></i>
                                            Drucken
                                        </button>

                                        <button class="transparent" type="button" @click=${() => setClipboard(url)}>
                                            <i class="clipboard"></i>
                                            Kopieren
                                        </button>

                                        <button
                                            class="transparent nowrap"
                                            type="button"
                                            @click=${this._shareViaMessage}
                                            ?disabled=${!app.hasPermission("manage.employees.messages")}
                                        >
                                            <i class="envelope"></i>
                                            Versenden
                                        </button>

                                        ${!app.hasPermission("manage.employees.messages")
                                            ? html`
                                                  <ptc-popover
                                                      class="tooltip"
                                                      trigger="hover"
                                                      non-interactive
                                                      style="width: 15em"
                                                  >
                                                      Das Versenden von Nachrichten erfordert die Berechtigung
                                                      <strong>Mitarbeiter / Nachrichten Versenden</strong>.
                                                  </ptc-popover>
                                              `
                                            : ""}
                                    </div>
                                `
                            ),
                            html`
                                <div class="padded center-aligning center-justifying vertical layout">
                                    <ptc-spinner active></ptc-spinner>
                                </div>
                            `
                        )}
                    </div>
                </ptc-popover>

                <button title="Anzeige" class="slim transparent noprint"><i class="tv-retro"></i></button>

                <ptc-popover style="width: 20em" class="unpadded">
                    <div class="padded border-bottom semibold center-aligning spacing horizontal layout">
                        <div class="stretch half-padded"><i class="tv-retro"></i> Anzeige</div>
                        <div class="small tabs">
                            <button
                                @click=${() => app.updateSettings({ rosterDisplayMode: "regular" })}
                                ?active=${app.settings.rosterDisplayMode === "regular"}
                                class="slim"
                                title="Normal"
                            >
                                <i class="grid-2"></i>
                            </button>
                            <button
                                @click=${() => app.updateSettings({ rosterDisplayMode: "compact" })}
                                ?active=${app.settings.rosterDisplayMode === "compact"}
                                class="slim"
                                title="Kompakt"
                            >
                                <i class="grid-3"></i>
                            </button>
                            <button
                                @click=${() => app.updateSettings({ rosterDisplayMode: "minimal" })}
                                ?active=${app.settings.rosterDisplayMode === "minimal"}
                                class="slim"
                                title="Minimal"
                            >
                                <i class="grid-4"></i>
                            </button>
                        </div>
                    </div>

                    <div class="half-padded">
                        <ptc-checkbox-button
                            buttonClass="slim transparent"
                            .checked=${live(app.settings.rosterDisplayTargets)}
                            @click=${() =>
                                app.updateSettings({ rosterDisplayTargets: !app.settings.rosterDisplayTargets })}
                            .label=${html`<i class="tasks-alt"></i> Stundenvorgaben`}
                            ?disabled=${app.settings.rosterDisplayMode === "minimal"}
                        >
                        </ptc-checkbox-button>

                        <ptc-checkbox-button
                            buttonClass="slim transparent"
                            .checked=${live(app.settings.rosterDisplayUnassigned)}
                            @click=${() =>
                                app.updateSettings({ rosterDisplayUnassigned: !app.settings.rosterDisplayUnassigned })}
                            .label=${html`<i class="user-slash"></i> Nicht Zugewiesen`}
                            ?disabled=${app.settings.rosterDisplayMode === "minimal"}
                        >
                        </ptc-checkbox-button>

                        <ptc-checkbox-button
                            buttonClass="slim transparent"
                            .checked=${live(app.settings.rosterDisplayCosts)}
                            @click=${() => app.updateSettings({ rosterDisplayCosts: !app.settings.rosterDisplayCosts })}
                            .label=${html`<i class="chart-line"></i> Kostenanalyse`}
                            ?hidden=${!app.hasPermission(`manage.roster.costs`)}
                        >
                        </ptc-checkbox-button>

                        <ptc-checkbox-button
                            buttonClass="slim transparent"
                            .checked=${live(app.settings.rosterMirrorDepartments)}
                            @click=${() =>
                                app.updateSettings({ rosterMirrorDepartments: !app.settings.rosterMirrorDepartments })}
                            .label=${html`<i class="clone"></i> Schichten Spiegeln`}
                        >
                        </ptc-checkbox-button>

                        <ptc-checkbox-button
                            buttonClass="slim transparent"
                            .checked=${live(app.settings.rosterDisplayAvailabilities)}
                            @click=${() =>
                                app.updateSettings({
                                    rosterDisplayAvailabilities: !app.settings.rosterDisplayAvailabilities,
                                })}
                            .label=${html`<i class="comment-check"></i> Verfügbarkeiten`}
                        >
                        </ptc-checkbox-button>

                        <ptc-checkbox-button
                            buttonClass="slim transparent"
                            .checked=${live(app.settings.rosterDisplayTimeBalances)}
                            @click=${() =>
                                app.updateSettings({
                                    rosterDisplayTimeBalances: !app.settings.rosterDisplayTimeBalances,
                                })}
                            .label=${html`<i class="plus-minus"></i> Überst. Vorher/Nachher`}
                        >
                        </ptc-checkbox-button>

                        <ptc-checkbox-button
                            buttonClass="slim transparent"
                            .checked=${live(app.settings.rosterDisplayNotes)}
                            @click=${() =>
                                app.updateSettings({
                                    rosterDisplayNotes: !app.settings.rosterDisplayNotes,
                                })}
                            .label=${html`<i class="sticky-note"></i> Dienstplannotizen`}
                        >
                        </ptc-checkbox-button>

                        <ptc-checkbox-button
                            buttonClass="slim transparent"
                            .checked=${live(app.settings.rosterDisplaySuggestions)}
                            @click=${() =>
                                app.updateSettings({
                                    rosterDisplaySuggestions: !app.settings.rosterDisplaySuggestions,
                                })}
                            .label=${html`<i class="lightbulb-on"></i> Vorschläge & Favoriten`}
                        >
                        </ptc-checkbox-button>
                    </div>
                    <div class="padded border-top vertical layout">
                        <button
                            class="small orange box"
                            @click=${() => app.updateSettings({ rosterUseNewVersion: false })}
                        >
                            <i class="rotate-left"></i> Zur alten Dienstplanansicht
                        </button>
                    </div>
                </ptc-popover>

                <button title="Vorlagen" class="slim transparent noprint">
                    <i class="floppy-disk-circle-arrow-right"></i>
                </button>

                <ptc-popover class="unpadded" style="width: 25em" id="rosterTemplatesPopover">
                    <div class="padded border-bottom semibold center-aligning spacing horizontal layout">
                        <div class="stretch half-padded"><i class="floppy-disk-circle-arrow-right"></i> Vorlagen</div>
                        <div>
                            <button
                                class="small slim transparent"
                                type="button"
                                @click=${this._createRosterTemplate}
                                ?disabled=${!isWeekRange}
                            >
                                <i class="plus"></i>
                            </button>
                        </div>

                        ${!isWeekRange
                            ? html`
                                  <ptc-popover trigger="hover" class="tooltip">
                                      <div class="orange colored-text text-left-aligning">
                                          <i class="exclamation-triangle"></i> Das Erstellen von Vorlagen ist nur in der
                                          <strong>Wochenansicht</strong> möglich (Montag bis Freitag). Wechseln Sie in
                                          die Wochenansicht um fortzufahren.
                                      </div>
                                  </ptc-popover>
                              `
                            : ""}
                    </div>

                    <ptc-scroller style="max-height: 80vh;">
                        ${!this._venue.rosterTemplates.length
                            ? html`<div class="double-padded subtle">
                                  Klicken Sie auf den <i class="bold plus"></i> button, um eine neue Vorlage zu
                                  erstellen.
                              </div>`
                            : ""}
                        ${this._venue.rosterTemplates
                            .filter((template) =>
                                template.shifts.every((shift) => {
                                    const position = app.getPosition(shift.position)?.position;
                                    return app.hasAccess({ position });
                                })
                            )
                            .map((template) => {
                                const departments = this._getTemplateDepartments(template);
                                return html`
                                    <div
                                        class="double-padded border-bottom click relative"
                                        @click=${() => this._selectRosterTemplate(template)}
                                    >
                                        <div class="bottom-margined ellipsis semibold">${template.name}</div>
                                        <div class="tiny pills">
                                            ${departments.map(
                                                ({ name, color, count }) => html`
                                                    <div
                                                        class="pill"
                                                        style="--color-highlight: ${colors[color] || color}"
                                                    >
                                                        ${name}
                                                        <span class="bold">${count}</span>
                                                    </div>
                                                `
                                            )}
                                        </div>
                                        <button
                                            class="small slim transparent margined absolute top right reveal-on-parent-hover"
                                            @click=${(e: MouseEvent) => {
                                                e.stopPropagation();
                                                this._deleteRosterTemplate(template);
                                            }}
                                        >
                                            <i class="trash"></i>
                                        </button>
                                    </div>
                                `;
                            })}
                    </ptc-scroller>
                </ptc-popover>

                <button title="Einstellungen" class="slim transparent noprint">
                    <i class="wrench"></i>
                </button>

                <ptc-popover class="popover-menu">
                    <button @click=${this._showAutoAssign}>
                        <i class="robot"></i> Schichten Automatisch Zuweisen
                    </button>

                    <button @click=${() => this._clearRosterDialog.show()}>
                        <i class="trash-alt"></i> Dienstplan Leeren
                    </button>
                </ptc-popover>
            </div>
        `;
    }

    render() {
        if (!app.accessibleVenues.length) {
            return html`
                <div class="fullbleed spacing centering vertical layout">
                    <div class="large bold">Nicht so schnell!</div>
                    <div class="padded" style="max-width: 30em;">
                        Bevor Sie mit der Dienstplanung beginnen können müssen Sie zunächst Ihre Standorte und
                        Abteilungen einrichten!
                    </div>
                    <button @click=${() => this.go("settings/venues")}>
                        Arbeitsbereiche Definieren <i class="arrow-right"></i>
                    </button>
                </div>
            `;
        }

        if (!this._venue || !this._data) {
            return html`
                <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                    <ptc-spinner active></ptc-spinner>
                </div>
            `;
        }

        return html`
            <div class="fullbleed vertical layout stretch collapse main-outer ${app.settings.rosterDisplayMode}">
                ${this._renderHeader()} ${this._renderRosterTemplatePreview()}

                <div class="stretch collapse horizontal layout">
                    <div
                        class="main stretch collapse ${app.settings.rosterDisplayCosts ? "show-charts" : ""} ${app
                            .settings.rosterDisplayTargets
                            ? "show-targets"
                            : ""}"
                    >
                        ${this._renderDayHeader()} ${this._data.departments.map((dep) => this._renderDepartment(dep))}
                        ${this._renderCosts()}
                    </div>

                    ${this._displayAutoAssignMenu
                        ? this._renderAutoAssignMenu()
                        : this._activeDate
                          ? this._renderDayMenu()
                          : this._renderGlobalShiftSuggestions()}
                </div>
            </div>

            <ptc-dialog id="clearRosterDialog">
                <div class="padded center-aligning horizontal layout border-bottom">
                    <div class="half-padded semibold stretch"><i class="trash"></i> Dienstplan Leeren</div>
                    <button class="slim transparent" @click=${() => this._clearRosterDialog.hide()}>
                        <i class="times"></i>
                    </button>
                </div>

                <form
                    class="padded spacing vertical layout"
                    @submit=${this._clearTimeEntries}
                    @keydown=${(e: Event) => e.stopPropagation()}
                >
                    <div class="subtle padded box">
                        <i class="info-circle"></i> <strong>Hinweis</strong>: Es werden nur jene Schichten gelöscht, die
                        den <strong>Filterkriterien</strong> des aktuellen Tabs entsprechen.
                        <a href="https://pentacode.app/hilfe/handbuch/dienstplan/#dienstplan-leeren" target="_blank">
                            Mehr Infos ➜
                        </a>
                    </div>

                    <ptc-checkbox-button
                        .label=${html`<i class="calendar"></i> Geplante Schichten`}
                        name="planned"
                        buttonClass="slim ghost"
                        checked
                    >
                    </ptc-checkbox-button>

                    <ptc-checkbox-button
                        .label=${html`<i class="check"></i> Abgeschlossene Schichten`}
                        name="finished"
                        buttonClass="slim ghost"
                    >
                    </ptc-checkbox-button>

                    <div class="vertical layout">
                        <button class="negative">Dienstplan Leeren</button>
                    </div>
                </form>
            </ptc-dialog>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
